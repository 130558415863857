// CustomSlider.js
import React, { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/system";

const StyledSlider = styled(Slider)({
  width: 60, // Adjust width as needed
  height: 6,
  borderRadius: 10,
  padding: 10,
  "& .MuiSlider-track": {
    backgroundColor: "#333",
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#d9d9d9",
    opacity: 1,
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "red",
    border: "3px solid #aaa",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontSize: 9,
    "&::before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      backgroundColor: "red",
      zIndex: -1,
    },
  },
});

const CustomSlider = ({ onSliderChange, currentstate }) => {
  const [value, setValue] = useState(currentstate);

  useEffect(() => {
    setValue(currentstate);
  }, [currentstate]);

  const handleChange = (event, newValue) => {
    onSliderChange(newValue); // Call the parent callback with the new slider value
  };

  return (
    <StyledSlider
      defaultValue={value}
      min={0}
      max={20}
      onChange={handleChange} // Attach the change handler
      valueLabelDisplay="auto" // Show the value on the slider thumb
      aria-label="custom slider"
      color="secondary"
    />
  );
};

export default CustomSlider;
