import React from "react";
import { GiReceiveMoney, GiShoppingCart } from "react-icons/gi";
import { IoPeopleOutline } from "react-icons/io5";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

const Adminboxeslink = ({ boxlabel, linklabel, textnumber, bgcolor, icon }) => {
  const Iconobj = {
    1: <GiReceiveMoney size={40} className="mr-2" fill="#00000085" />,
    2: <IoPeopleOutline size={40} className="mr-2" fill="#00000085" />,
    3: <GiShoppingCart size={40} className="mr-2" />,
  };

  return (
    <div
      className={`max-w-[500px] min-w-[170px] flex-grow 800px:w-[30%] min-h-[20vh] h-[160px] ${bgcolor} shadow rounded px-4 py-5`}
    >
      <div className="flex items-center">
        {Iconobj[icon]}
        <h3 className={`text-[16px] font-[600] leading-5 text-[#00000085]`}>
          {boxlabel}
        </h3>
      </div>
      <h5 className="pt-2 pl-[36px] text-[22px] font-[800]">
        {icon === 1 ? (
          <NumberFormat
            value={textnumber}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"XOF "}
          />
        ) : (
          textnumber
        )}
      </h5>
      <Link to="/admin-sellers">
        {icon !== 1 && (
          <h5 className="pt-4 text-[13px] text-[#077f9c] hover:underline">
            {linklabel}
          </h5>
        )}
      </Link>
    </div>
  );
};

export default Adminboxeslink;
