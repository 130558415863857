import React, { useEffect, useState } from "react";
import { server } from "../../server";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  AiFillCloseCircle,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { Button } from "@mui/material";
import { IoCloseCircleSharp, IoWarning } from "react-icons/io5";
import {
  createCategories,
  deleteCategory,
  getAllCategories,
} from "../../redux/actions/category";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";

const AllCategories = () => {
  const dispatch = useDispatch();
  const { categorylist } = useSelector((state) => state.category);
  const [data, setData] = useState(categorylist);
  const [views, setViews] = useState(false);
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [image, setImage] = useState([]);
  const [imageicon, setImageicon] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [orderId, setOrderId] = useState(0);
  const [categoryfield, setCategoryfield] = useState({
    title: "",
    subtitle: "",
  });

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  useEffect(() => {
    setData(categorylist);
  }, [categorylist]);

  const handleCollectionformSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", categoryfield.title);
    formData.append("subTitle", categoryfield.subtitle);
    for (let i = 0; i < image.length; i++) {
      formData.append("image_Url", image[i]);
    }

    dispatch(createCategories(formData));
    setCategoryfield({ title: "", subtitle: "" });
    setViews(!views);
  };

  const handleDelete = (id) => {
    dispatch(deleteCategory(id));
  };

  // const handleEdit = (id) => {};

  const columns = [
    { field: "sn", headerName: "#SN", minWidth: 60, flex: 0 },
    { field: "id", headerName: "Category Id", minWidth: 150, flex: 0.7 },
    {
      field: "title",
      headerName: "Name",
      minWidth: 180,
      flex: 0.8,
    },
    {
      field: "subTitle",
      headerName: "Subtitle",
      minWidth: 100,
      flex: 0.8,
    },
    {
      field: "Edit",
      flex: 0.6,
      minWidth: 100,
      headerName: "Edit",
      type: "",
      renderCell: (params) => {
        return (
          <div className="flex items-start gap-2">
            <Button
              onClick={() => {
                setOrderId(params.id);
                // setOpen(true);
              }}
            >
              <AiOutlineEdit size={20} />
            </Button>
          </div>
        );
      },
    },
    {
      field: "Delete",
      flex: 0.6,
      minWidth: 100,
      headerName: "Delete",
      type: "",
      renderCell: (params) => {
        return (
          <div className="flex items-start gap-2">
            <Button
              onClick={() => {
                setCategoryId(params.id);
                setOpen(true);
              }}
            >
              <AiOutlineDelete size={20} />
            </Button>
          </div>
        );
      },
    },
  ];

  const row = data?.map((item, index) => ({
    sn: index + 1,
    id: item._id, // Assign sequential numbers starting from 1
    title: item.title,
    subTitle: item.subTitle,
  }));

  const handleAddNewCategory = () => {
    setViews(!views);
  };

  const handleClose = () => {
    setHide(!hide);
  };

  const handleCloseModal = () => {
    setViews(!views);
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setCategoryfield({
      ...categoryfield,
      [name]: value,
    });
  };

  const handleCategoryIconChange = (e) => {
    const files = Array.from(e.target.files);
    const maxSizeInKB = 50; // Maximum file size in KB
    setImageicon([]);
    let oversized = false;

    files.forEach((file) => {
      const fileSizeInKB = file.size / 1024; // Convert size to KB

      if (fileSizeInKB > maxSizeInKB) {
        alert(
          `The file ${file.name} exceeds the maximum size of ${maxSizeInKB}KB.`
        );
        oversized = true;
      } else {
        setImage(e.target.files);

        const reader = new FileReader();

        reader.onload = () => {
          if (reader.readyState === 2) {
            setImageicon((old) => [...old, reader.result]);
          }
        };
        reader.readAsDataURL(file);
      }
    });
  };

  const handleReduceImage = (imageIndex) => {
    const filteredImage = imageicon.filter(
      (currentItem) => imageicon.indexOf(currentItem) !== imageIndex
    );
    setImage(filteredImage);
    setImageicon(filteredImage);
  };

  return (
    <div className="w-full h-full flex justify-center pt-5 relative">
      <div className="w-[97%] ml-2">
        <h3 className="text-[16px] font-[800] pb-2">All Categories</h3>
        <div className="w-full min-h-[45vh] bg-white rounded">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            slots={{ toolbar: GridToolbar }}
          />
        </div>

        <div className="w-full p-2 flex items-center justify-end">
          <button
            className="text-[white] text-[12px] bg-[green] font-[600] p-3"
            onClick={handleAddNewCategory}
          >
            NEW CATEGORY
          </button>
        </div>
      </div>

      {views && (
        <div className="w-full bg-[#00000029] h-full flex justify-center items-center fixed top-0 left-0 z-50">
          <div className="flex max-w-[600px] flex-col gap-3 items-center bg-[white] w-[80%] p-5 py-7 min-h-[200px] pb-[50px]">
            <div className="w-full flex items-center justify-center relative">
              <h5 className="text-[20px] font-[600] text-center">
                Create Categories
              </h5>

              <IoCloseCircleSharp
                size={26}
                color="#002D62"
                onClick={handleCloseModal}
                className="cursor-pointer absolute right-0"
              />
            </div>

            {hide && (
              <div className="flex gap-2 text-[10px] p-2 bg-[orange] items-center w-fit rounded-[3px]">
                <IoWarning size={16} color="yellow" />
                <p className="text-[#fff]">
                  Note: you can only create maximum of 11 collections
                </p>

                <IoCloseCircleSharp
                  size={16}
                  color="yellow"
                  onClick={handleClose}
                  className="cursor-pointer"
                />
              </div>
            )}

            <form
              onSubmit={handleCollectionformSubmit}
              className="flex flex-col gap-3 text-[13px] w-[80%] min-w-[300px]"
            >
              <div className="flex gap-2 w-full">
                <div className="w-full">
                  <label className="pb-2">
                    Category Title<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={categoryfield.title}
                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    onChange={(e) => handleCategoryChange(e)}
                    placeholder="Enter Category Title"
                  />
                </div>
              </div>

              <div className="flex gap-2 w-full">
                <div className="w-full">
                  <label className="pb-2">
                    Category Subtitle <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="subtitle"
                    value={categoryfield.subtitle}
                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    onChange={(e) => handleCategoryChange(e)}
                    placeholder="Enter Category Subtitle"
                  />
                </div>
              </div>

              <div className="flex gap-2 w-full">
                <div className="w-full">
                  <label className="pb-2">
                    Category Icon<span className="text-red-500">*</span>
                    <span className="ml-8 text-[10px] text-red-500 italic">
                      (maximum size of 50kb)
                    </span>
                  </label>
                  <input
                    type="file"
                    name="categoryicon"
                    id="upload"
                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    onChange={handleCategoryIconChange}
                  />

                  <div className="w-full flex items-center flex-wrap">
                    <label htmlFor="upload">
                      <AiOutlinePlusCircle
                        size={30}
                        className="mt-3"
                        color="#555"
                      />
                    </label>

                    {imageicon &&
                      imageicon?.map((i, index) => (
                        <div className="picture_frame_container">
                          <img
                            src={i}
                            alt=""
                            className="h-[50px] w-[50px] object-cover m-2"
                          />
                          <AiFillCloseCircle
                            id="close-icon"
                            onClick={() => handleReduceImage(index)}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="flex gap-2 w-full">
                <div className="w-full">
                  <input
                    type="submit"
                    name="submit"
                    value="SUBMIT"
                    className="mt-2 cursor-pointer hover:bg-[#002D62] bg-[#002e6253] text-white appearance-none block w-full p-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {open && (
        <div className="w-full fixed top-0 left-0 z-50 bg-[#00000039] flex items-center justify-center h-screen">
          <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
            <div className="w-full flex justify-end cursor-pointer">
              <RxCross1 size={25} onClick={() => setOpen(false)} />
            </div>
            <h3 className="text-[15px] text-[700] text-center">
              Are you sure you want to delete this Order?
            </h3>

            <div className="w-full flex items-center justify-center">
              <div
                className={`${styles.button} text-white text-[13px] !h-[42px] mr-4`}
                onClick={() => setOpen(false)}
              >
                Cancel
              </div>

              <div
                className={`${styles.button} text-white text-[13px] !h-[42px] ml-4`}
                onClick={() => {
                  setOpen(false);
                  handleDelete(categoryId);
                }}
              >
                Confirm
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllCategories;
