import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  isLoading: null,
  isloading: 0,
};

export const userReducer = createReducer(initialState, {
  LoadUserRequest: (state) => {
    state.isLoading = true;
  },
  LoadUserSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.isLoading = false;
    state.user = action.payload;
  },
  LoadUserFail: (state, action) => {
    state.isAuthenticated = false;
    state.isLoading = false;
    state.error = action.payload;
  },

  // update user information --- user
  updateUserInfoRequest: (state) => {
    state.isLoading = true;
  },
  updateUserInfoSuccess: (state, action) => {
    state.isLoading = false;
    state.user = action.payload;
  },
  updateUserInfoFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // update user address
  updateUserAddressRequest: (state) => {
    state.isLoading = true;
  },
  updateUserAddressSuccess: (state, action) => {
    state.isLoading = false;
    state.successMessage = action.payload.successMessage;
    state.user = action.payload.user;
  },
  updateUserAddressFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // delete user address
  deleteUserAddressRequest: (state) => {
    state.isLoading = true;
  },
  deleteUserAddressSuccess: (state, action) => {
    state.isLoading = false;
    state.successMessage = action.payload.successMessage;
    state.user = action.payload.user;
  },
  deleteUserAddressFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // delete user --- admin
  deleteUserRequest: (state) => {
    state.isloading = 1;
  },
  deleteUserSuccess: (state, action) => {
    state.isloading = 0;
    state.users = action.payload;
  },
  deleteUserFailed: (state, action) => {
    state.isloading = 0;
    state.error = action.payload;
  },

  // get all users --- admin
  getAllUsersRequest: (state) => {
    state.isloading = 2;
  },
  getAllUsersSuccess: (state, action) => {
    state.isloading = 0;
    state.users = action.payload;
  },
  getAllUsersFailed: (state, action) => {
    state.isloading = 0;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
  clearMessages: (state) => {
    state.successMessage = null;
  },
});
