import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: 0,
  commissionlist: [],
};

export const commissionReducer = createReducer(initialState, {
  // fetch all available commissions
  getAllCommissionRequest: (state) => {
    state.isLoading = 3;
  },

  getAllCommissionSuccess: (state, action) => {
    state.isLoading = 0;
    state.commissionlist = action.payload;
  },

  getAllCommissionFailed: (state, action) => {
    state.isLoading = 0;
    state.error = action.payload;
  },

  // create commission -admin
  createCommissionsRequest: (state) => {
    state.isLoading = 1;
  },

  createCommissionsSuccess: (state, action) => {
    state.isLoading = 0;
    state.commissionlist = action.payload;
  },

  createCommissionsFailed: (state, action) => {
    state.isLoading = 0;
    state.error = action.payload;
  },

  // remove/delete commission
  deleteCommissionRequest: (state) => {
    state.isLoading = 2;
  },

  deleteCommissionSuccess: (state, action) => {
    state.isLoading = 0;
    state.commissionlist = action.payload;
  },

  deleteCommissionFailed: (state, action) => {
    state.isLoading = 0;
    state.error = action.payload;
  },
});
