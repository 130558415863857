import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

// get All Products of a shop
export const getAllCommission = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllCommissionRequest",
    });

    const { data } = await axios.get(
      `${server}/commission/get-all-commission`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "getAllCommissionSuccess",
      payload: data.commissions,
    });
  } catch (error) {
    dispatch({
      type: "getAllCommissionFailed",
      payload: error.response.data.message,
    });
  }
};

// Create New Commission
export const createCommissions = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: "createCommissionsRequest",
    });

    const { data } = await axios.post(
      `${server}/commission/admin-create`,
      formData, // Pass the formData in the request body
      {
        headers: {
          "Content-Type": "multipart/form-data", // Specify the correct content type
        },
        withCredentials: true,
      }
    );
    toast.success(data.message);

    dispatch({
      type: "createCommissionsSuccess",
      payload: data.commissions,
    });
  } catch (error) {
    toast.error(error.response.data.message);

    dispatch({
      type: "createCommissionsFailed",
      payload: error.response.data.message,
    });
  }
};

// Add Delete Commission by ID
export const deleteCommission = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteCommissionRequest",
    });

    const { data } = await axios.delete(
      `${server}/commission/delete-admin/${id}`,
      {
        withCredentials: true,
      }
    );
    toast.success(data.message);
    dispatch({
      type: "deleteCommissionSuccess",
      payload: data.data,
    });
  } catch (error) {
    toast.error(error.response.data.message);

    dispatch({
      type: "deleteCommissionFailed",
      payload: error.response.data.message,
    });
  }
};
