import React from "react";
import styles from "../../styles/styles";
import ShopInfo from "../../components/Shop/ShopInfo";
import ShopProfileData from "../../components/Shop/ShopProfileData";
import ShopInfotop from "../../components/Shop/ShopInfoalt";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer";

const ShopPreviewPage = () => {
  return (
    <div className="flex flex-col ">
      <Header activeHeading={3} />
      <div className={`${styles.section} flex flex-col  bg-[#f5f5f5]`}>
        <div className="w-full gap-2 flex flex-col py-10 justify-start">
          <div className="w-full bg-[white] flex items-center">
            <ShopInfotop isOwner={false} />
          </div>
          <div className="800px:w-full 800px:mt-['unset'] rounded-[4px]">
            <ShopProfileData isOwner={false} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ShopPreviewPage;
