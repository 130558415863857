import React from 'react'
import './CollectionCard.css'
import Loadpending from '../Loadpending/Loadpending'

const CollectionCard4 = ({product, imgurl}) => {
  return (
    <div className='collectioncard-container_4'>
        <div className='image-container_2'>
          {/* <Loadpending/> */}
          {imgurl !== "" ?  <img className='w-full h-full' alt='alternative-img' src={imgurl}/> : <Loadpending/>}
        </div>
        <span>{product}</span>
    </div>
  )
}

export default CollectionCard4