import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  adminOrderLoading: 0,
};

export const orderReducer = createReducer(initialState, {
  // get all orders for user
  getAllOrdersUserRequest: (state) => {
    state.isLoading = true;
  },
  getAllOrdersUserSuccess: (state, action) => {
    state.isLoading = false;
    state.orders = action.payload;
  },
  getAllOrdersUserFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all orders for shop
  getAllOrdersShopRequest: (state) => {
    state.isLoading = true;
  },
  getAllOrdersShopSuccess: (state, action) => {
    state.isLoading = false;
    state.orders = action.payload;
  },
  getAllOrdersShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // Removing orders for admin
  adminRemoveOrdersRequest: (state) => {
    state.adminOrderLoading = 1;
  },
  adminRemoveOrdersSuccess: (state, action) => {
    state.adminOrderLoading = 0;
    state.adminOrders = action.payload;
  },
  adminRemoveOrdersFailed: (state, action) => {
    state.adminOrderLoading = 0;
    state.error = action.payload;
  },

  // get all orders for admin
  adminAllOrdersRequest: (state) => {
    state.adminOrderLoading = 2;
  },
  adminAllOrdersSuccess: (state, action) => {
    state.adminOrderLoading = 0;
    state.adminOrders = action.payload;
  },
  adminAllOrdersFailed: (state, action) => {
    state.adminOrderLoading = 0;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
