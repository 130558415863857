import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTocart } from "../../../redux/actions/cart";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import NumberFormat from "react-number-format";

const ProductDetailsCard = ({ setOpen, data }) => {
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  //   const [select, setSelect] = useState(false);

  const handleMessageSubmit = () => {};

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const incrementCount = () => {
    setCount(count + 1);
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < count) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  return (
    <div className="bg-[#fff]">
      {data ? (
        <div className="fixed w-full h-screen top-0 left-0 bg-[#00000030] z-40 flex items-center justify-center">
          <div className="w-[90%] 800px:w-[50%] h-[90vh] overflow-y-scroll 800px:h-[75vh] bg-white rounded-md shadow-sm relative p-4">
            <div className="w-full flex items-center justify-end">
              <AiFillCloseCircle size={30} onClick={() => setOpen(false)} />
            </div>

            <div className="block w-full 800px:flex gap-3">
              <div className="w-full 800px:w-[50%] flex flex-col gap-3">
                <div className="">
                  <img src={`${data.images && data.images[0]?.url}`} alt="" />
                </div>

                <div className="flex">
                  <Link
                    to={`/shop/preview/${data.shop._id}`}
                    className="flex items-center gap-3"
                  >
                    <img
                      src={`${data.images && data.images[0]?.url}`}
                      alt=""
                      className="w-[50px] h-[50px] rounded-full mr-2"
                    />
                    <div className="flex flex-col">
                      <h3 className="text-[14px] text-blue-400">
                        {data.shop._id}
                      </h3>
                      <h5 className="text-[14px]">{data?.ratings} Ratings</h5>
                    </div>
                  </Link>
                </div>

                <div className="flex w-full items-center justify-between">
                  {/* <div
                    className={`justify-center rounded-[4px] h-11 flex items-center bg-[green] px-3`}
                    onClick={handleMessageSubmit}
                  >
                    <span className="text-[#fff] flex items-center">
                      Send Message <AiOutlineMessage className="ml-1" />
                    </span>
                  </div> */}
                  <h5 className="text-[13px] text-[red] font-[500]">
                    (50) Sold out
                  </h5>
                </div>
              </div>

              <div className="w-full flex flex-col gap-4 800px:w-[50%] pl-[5px] pr-[5px]">
                <h1 className={`font-[600] text-[#333] text-[18px]`}>
                  {data.name}
                </h1>

                {/* <p className="text-[14px]">{data.description}</p> */}

                <div
                  className="w-full p-4 rounded-lg text-[13px]"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                />

                <div className="flex items-center justify-start gap-3">
                  <h4 className={`${styles.productDiscountPrice}`}>
                    <NumberFormat
                      className="font-[700] text-[17px]"
                      allowLeadingZeros={true}
                      value={data.discountPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"XOF"}
                    />
                  </h4>
                  <h3 className={`${styles.price}`}>
                    <NumberFormat
                      className="font-[700] text-[17px]"
                      allowLeadingZeros={true}
                      value={data.originalPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"XOF"}
                    />
                  </h3>
                </div>

                <div className="flex items-center justify-between pr-3">
                  <div className="flex align-center">
                    <button
                      className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-3 py-1 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                      onClick={decrementCount}
                    >
                      -
                    </button>
                    <div className="bg-gray-200 text-center font-[#333]-500 text-[13px] px-3 py-1 flex items-center">
                      {count}
                    </div>
                    <button
                      className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-r px-3 py-1 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                      onClick={incrementCount}
                    >
                      +
                    </button>
                  </div>

                  <div>
                    {click ? (
                      <AiFillHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() => removeFromWishlistHandler(data)}
                        color={click ? "red" : "#333"}
                        title="Remove from wishlist"
                      />
                    ) : (
                      <AiOutlineHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={() => addToWishlistHandler(data)}
                        title="Add to wishlist"
                      />
                    )}
                  </div>
                </div>

                <div
                  className={`justify-center rounded-[4px] h-11 flex items-center bg-[orange]`}
                  onClick={() => addToCartHandler(data._id)}
                >
                  <span className="text-[#fff] flex items-center">
                    Add to cart <AiOutlineShoppingCart className="ml-1" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductDetailsCard;
