import axios from "axios";
import React, { useEffect, useState } from "react";
import { server } from "../../server";

const CountDown = (data) => {
  // //console.log("data", data)
  // //console.log("obaloluwa")

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    if (
      typeof timeLeft.d === "undefined" &&
      typeof timeLeft.h === "undefined" &&
      typeof timeLeft.m === "undefined" &&
      typeof timeLeft.s === "undefined"
    ) {
      axios.delete(`${server}/event/delete-shop-event/${data?._id}`);
    }
    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const difference = +new Date(data?.Finish_Date) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        d: Math.floor(difference / (1000 * 60 * 60 * 24)),
        h: Math.floor((difference / (1000 * 60 * 60)) % 24),
        m: Math.floor((difference / 1000 / 60) % 60),
        s: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  }

  const timerComponents = Object.keys(timeLeft)?.map((interval, index) => {
    if (!timeLeft[interval]) {
      return null;
    }

    return (
      <span key={`${interval}-${index}`} className="text-[13px] text-[#fff]">
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div>
      {timerComponents?.length ? (
        timerComponents
      ) : (
        <span className="text-[yellow] text-[13px]">Time's Up</span>
      )}
    </div>
  );
};

export default CountDown;
