import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { BsCartPlus } from "react-icons/bs";
import styles from "../../styles/styles";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { removeFromWishlist } from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import NumberFormat from "react-number-format";

const Wishlist = ({ setOpenWishlist }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();

  const removeFromWishlistHandler = (data) => {
    dispatch(removeFromWishlist(data));
  };

  const addToCartHandler = (data) => {
    const newData = { ...data, qty: 1 };
    dispatch(addTocart(newData));
    setOpenWishlist(false);
  };

  return (
    <div className="fixed top-0 left-0 w-full bg-[#0000004b] h-screen z-10">
      <div className="fixed top-0 right-0 h-full w-[80%] overflow-y-auto 800px:w-[25%] bg-white flex flex-col justify-between shadow-sm">
        {wishlist && wishlist?.length === 0 ? (
          <div className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full justify-end pt-5 pr-5 fixed top-3 right-3">
              <RxCross1
                size={25}
                className="cursor-pointer"
                onClick={() => setOpenWishlist(false)}
              />
            </div>
            <h5>Wishlist Items is empty!</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex w-full items-center justify-between py-3 px-2">
                {/* Item length */}
                <div className={`${styles.noramlFlex} p-1`}>
                  <AiOutlineHeart size={25} />
                  <h5 className="pl-2 text-[15px] font-[500]">
                    {wishlist && wishlist?.length} items
                  </h5>
                </div>

                <div
                  className="p-1 rounded-full bg-[#3333] cursor-pointer"
                  onClick={() => setOpenWishlist(false)}
                >
                  <RxCross1 size={20} />
                </div>
              </div>

              {/* cart Single Items */}
              <div className="w-full border-t">
                {wishlist &&
                  wishlist?.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      removeFromWishlistHandler={removeFromWishlistHandler}
                      addToCartHandler={addToCartHandler}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({ data, removeFromWishlistHandler, addToCartHandler }) => {
  const [value, setValue] = useState(1);
  const totalPrice = data.discountPrice * value;

  return (
    <div className="border-b py-3 px-2 w-full">
      <div className="w-full flex gap-[10px] 800px:flex items-center">
        <img
          src={`${data?.images[0]?.url}`}
          alt=""
          className="w-[70px] h-min rounded-[5px]"
        />

        <div className="pl-[5px]">
          <h1 className="text-[13px] max-h-[60px] overflow-hidden text-ellipsis">
            {data.name}
          </h1>
          <h4 className="font-[600] pt-3 800px:pt-[3px] text-[17px] text-[#d02222] font-Roboto">
            <NumberFormat
              value={totalPrice}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"XOF "}
            />
          </h4>
        </div>

        <div className="min-h-[80px] flex flex-col justify-between items-center">
          <div className="p-1">
            <RxCross1
              className="cursor-pointer 800px:mb-['unset'] 800px:ml-['unset'] mb-2"
              onClick={() => removeFromWishlistHandler(data)}
            />
          </div>

          <div className="p-1 rounded-full hover:bg-[#3333]">
            <BsCartPlus
              size={20}
              className="cursor-pointer"
              tile="Add to cart"
              onClick={() => addToCartHandler(data)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
