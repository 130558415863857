import React from 'react'
import './Cardskeleton.css'
import Carousel from 'react-multi-carousel'
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'
import { Responsive } from '../../../../pages/Body/responsive/responsive'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const Cardskeleton = ({cards, counts}) => {
  return (
    <SkeletonTheme>
      <Carousel slidesToSlide= {1} infinite={true} responsive={Responsive} customLeftArrow={<FaChevronCircleLeft id="carousel-icons-left"/>} customRightArrow={<FaChevronCircleRight id="carousel-icons-right" />}> 
        {Array(cards).fill(0)?.map((_, index) =>
          <div className={counts === 1 ? 'skeleton-card-container' : counts === 3 ? 'skeleton-collectioncard-container_' : counts === 5 ? 'skel-collectioncard-container_3' : ''} key={index}>
            <Skeleton containerClassName={counts === 1 ? 'skeleton-image-container' : counts === 3 ? 'skeleton-my-image-container' : counts === 5 ? 'skel-my-image-container' : ''} width='100%' height='100%'/>
            <Skeleton containerClassName='skeleton-text' height={counts === 1 ? '' : 8} count={counts}/>
          </div>
        )}
      </Carousel>
    </SkeletonTheme>
  )
}

export default Cardskeleton