import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

// create product
export const createProduct = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: "productCreateRequest",
    });

    const { data } = await axios.post(
      `${server}/product/create-product`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    dispatch({
      type: "productCreateSuccess",
      payload: data.product,
    });
  } catch (error) {
    console.log("my error: ", error.response.data.message);
    dispatch({
      type: "productCreateFail",
      payload: error.response.data.message,
    });
  }
};

// edit product of a shop
export const editProduct = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: "editProductRequest",
    });

    const { data } = await axios.put(
      `${server}/product/update-product/${id}`,
      formData, // Pass the formData in the request body
      {
        headers: {
          "Content-Type": "multipart/form-data", // Specify the correct content type
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: "editProductSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "editProductFailed",
      payload: error.response?.data?.message || "Failed to update product",
    });
  }
};

// get All Products of a shop
export const getAllProductsShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsShopRequest",
    });

    const { data } = await axios.get(
      `${server}/product/get-all-products-shop/${id}`
    );
    dispatch({
      type: "getAllProductsShopSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsShopFailed",
      payload: error.response.data.message,
    });
  }
};

// Admin get All Products
export const getAllProductsAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsAdminRequest",
    });

    const { data } = await axios.get(`${server}/product/admin-all-products`, {
      withCredentials: true,
    });
    dispatch({
      type: "getAllProductsAdminSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsAdminFailed",
      payload: error.response.data.message,
    });
  }
};

// Seller delete product of a shop
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteProductRequest",
    });

    const { data } = await axios.delete(
      `${server}/product/delete-shop-product/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "deleteProductSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteProductFailed",
      payload: error.response.data.message,
    });
  }
};

// Admin delete product of a shop
export const deleteProductAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "admindeleteProductRequest",
    });

    const { data } = await axios.delete(
      `${server}/product/admin-delete-shop-product/${id}`,
      {
        withCredentials: true,
      }
    );
    toast.success(data.message);

    dispatch({
      type: "admindeleteProductSuccess",
      payload: data.products,
    });
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: "admindeleteProductFailed",
      payload: error.response.data.message,
    });
  }
};

// get all products
export const getAllProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsRequest",
    });

    const { data } = await axios.get(`${server}/product/get-all-products`);
    dispatch({
      type: "getAllProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsFailed",
      payload: error.response.data.message,
    });
  }
};

// Update a field in the product collection
export const updateProduct = (slidervalue) => async (dispatch) => {
  try {
    dispatch({
      type: "updateProductRequest",
    });

    console.log("Slider Value Type:", typeof slidervalue.value);

    const { data } = await axios.put(
      `${server}/product/update-visibility/${slidervalue.id}`,
      { visibility: Number(slidervalue.value) },
      {
        headers: {
          "Content-Type": "application/json", // Set to application/json
        },
        withCredentials: true,
      }
    );

    toast.success(data.message);
    dispatch({
      type: "updateProductSuccess",
      payload: data.product,
    });
  } catch (error) {
    toast.error(error.response?.data?.message || "Failed to update product");
    dispatch({
      type: "updateProductFail",
      payload: error.response?.data?.message,
    });
  }
};
