import React from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";
import AllUsers from "../components/Admin/AllUsers";
import Loader from "../components/Loading/Loading";
import { useSelector } from "react-redux";

const AdminDashboardUsers = () => {
  const { isloading } = useSelector((state) => state.user);

  return (
    <div className="w-full overflow-x-auto h-[100vh] relative">
      <AdminHeader />

      {isloading > 0 && (
        <Loader
          type="bars"
          color="blue"
          text={
            isloading === 1
              ? "Removing User"
              : isloading === 2
              ? "Fetching User"
              : isloading === 3
              ? "Updating User Profile"
              : isloading === 4 && "Updating User Address"
          }
          height="30%"
          width="80%"
        />
      )}

      <div className="flex items-start justify-between w-full h-full overflow-y-auto relative">
        <div className="w-[50px] 800px:w-[300px] h-full sticky top-0 left-0">
          <AdminSideBar active={4} />
        </div>
        <AllUsers />
      </div>
    </div>
  );
};

export default AdminDashboardUsers;
