import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

// get all sellers --- admin
export const getAllSellers = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllSellersRequest",
    });

    const { data } = await axios.get(`${server}/shop/admin-all-sellers`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllSellersSuccess",
      payload: data.sellers,
    });
  } catch (error) {
    dispatch({
      type: "getAllSellerFailed",
      //   payload: error.response.data.message,
    });
  }
};

// delete seller --- admin
export const removeSeller = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "removeSellerRequest",
    });

    const { data } = await axios.delete(`${server}/shop/delete-seller/${id}`, {
      withCredentials: true,
    });
    toast.success(data.message);
    dispatch({
      type: "removeSellerSuccess",
      payload: data.sellers,
    });
  } catch (error) {
    toast.error(error.response.data.message);

    dispatch({
      type: "removeSellerFailed",
      payload: error.response.data.message,
    });
  }
};

// load seller
export const loadSeller = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadSellerRequest",
    });
    const { data } = await axios.get(`${server}/shop/getSeller`, {
      withCredentials: true,
    });

    dispatch({
      type: "LoadSellerSuccess",
      payload: data.seller,
    });
  } catch (error) {
    dispatch({
      type: "LoadSellerFail",
      payload: error?.response?.data?.message,
    });
  }
};
