import React from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";
import AllProducts from "../components/Admin/AllProducts";
import { useSelector } from "react-redux";
import Loader from "../components/Loading/Loading";

const AdminDashboardProducts = () => {
  const { isLoading } = useSelector((state) => state.products);

  return (
    <div className="w-full overflow-x-auto h-[100vh] relative">
      <AdminHeader />

      {isLoading > 0 && (
        <Loader
          type="bars"
          color="blue"
          text={
            isLoading === 1
              ? "Removing Product"
              : isLoading === 2
              ? "Fetching Product"
              : isLoading === 3 && "Updating Product"
          }
          height="30%"
          width="80%"
        />
      )}

      <div className="flex items-start justify-between w-full h-full overflow-y-auto relative">
        <div className="w-[50px] 800px:w-[300px] h-full sticky top-0 left-0">
          <AdminSideBar active={5} />
        </div>
        <AllProducts />
      </div>
    </div>
  );
};

export default AdminDashboardProducts;
