import {
  GET_COUPONS_REQUEST,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAIL,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
} from "./actionTypes";

// Initial State
const initialState = {
  coupons: [],
  isLoading: false,
  error: null,
};

// Reducer
export const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUPONS_REQUEST:
    case CREATE_COUPON_REQUEST:
    case DELETE_COUPON_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_COUPONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coupons: action.payload,
      };
    case CREATE_COUPON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coupons: action.payload,
      };
    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coupons: state.coupons.filter(
          (coupon) => coupon._id !== action.payload
        ),
      };
    case GET_COUPONS_FAIL:
    case CREATE_COUPON_FAIL:
    case DELETE_COUPON_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
