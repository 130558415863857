// capture button click
export const captureButtonClick = () => async (dispatch) => {
  dispatch({
    type: "CAPTURE_BUTTON_CLICK",
  });
};

export const EditBitton = (overlaytab) => ({
  type: "OverlayTab",
  payload: overlaytab,
});
