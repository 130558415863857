import React from "react";
import "./RefundPolicy.css"; // Optional for styling
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer";

const RefundPolicy = () => {
  return (
    <div className="w-full flex flex-col gap-2">
      <Header activeHeading={3} />
      <div className="refund-policy-container">
        <h1 className="text-[25px] font-bold">Returns and Refunds Policy</h1>

        {/* 1. Introduction */}
        <section>
          <h2 className="text-[16px] font-bold">1. Introduction</h2>
          <p>
            This policy applies to all products listed on our platform. Please
            ensure the product meets the return conditions before returning.
          </p>
        </section>

        {/* 2. Return Periods */}
        <section>
          <h2 className="text-[16px] font-bold">
            2. Return Period and Conditions for Acceptance of Returns
          </h2>
          <table className="refund-table">
            <thead>
              <tr>
                <th>Reason for Return</th>
                <th>Return Period by Product Category</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Change of mind</td>
                <td>Not allowed</td>
              </tr>
              <tr>
                <td>Received Item damaged or defective</td>
                <td>Maximum of 72hrs</td>
              </tr>
              <tr>
                <td>Wrong item delivered</td>
                <td>7 days (all categories)</td>
              </tr>
              {/* Add more rows as per policy */}
            </tbody>
          </table>
        </section>

        {/* 3. Items That Cannot Be Returned */}
        <section>
          <h2 className="text-[16px] font-bold">
            3. Items that Cannot be Returned
          </h2>
          <ul className="pl-10">
            <li className="list-disc">Items with damage caused by misuse</li>

            <li className="list-disc">Personal care products once opened</li>
            <li className="list-disc">
              Underwear, earrings, and health products
            </li>
            <li className="list-disc">
              Items Tested at the point of delivery cannot be retruned
            </li>

            {/* Add more conditions */}
          </ul>
        </section>

        {/* 4. Packaging Returns */}
        <section>
          <h2 className="text-[16px] font-bold">4. Packaging Returns</h2>
          <p className="pl-6">
            Returned products must be in original packaging with all accessories
            included.
          </p>
        </section>

        {/* 5. Refunds */}
        <section>
          <h2 className="text-[16px] font-bold">5. Refunds</h2>
          <ul className="pl-10">
            <li className="list-disc">
              Refunds will be processed within 7 days upon acceptance of return.
            </li>
            <li className="list-disc">
              Refunds will be issued to the original payment method.
            </li>
          </ul>
        </section>

        {/* 6. Rejected Return Requests */}
        <section>
          <h2 className="text-[16px] font-bold">
            6. Rejected Return and Refund Requests
          </h2>
          <p className="pl-6">
            Returns may be rejected for items that don’t meet return conditions,
            such as missing accessories or product misuse.
          </p>
        </section>

        {/* 7. No Exchange */}
        <section>
          <h2 className="text-[16px] font-bold">7. No Exchange</h2>
          <p className="pl-6">
            We do not offer product exchanges. You may return and reorder as
            needed.
          </p>
        </section>

        {/* 8. Further Information */}
        <section>
          <h2 className="text-[16px] font-bold">8. Further Information</h2>
          <p className="pl-6">
            Contact customer service for more details or questions regarding the
            return process.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
