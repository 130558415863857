import React from 'react';
import './Ads.css'

const Ads = () => {
  // const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const texts = ['Welcome to Kira Store, you can get your products at a very affordable price', 'Welcome to Kira Store, you can get your products at a very affordable price', 'Welcome to Kira Store, you can get your products at a very affordable price'];

  return (
    <div className="marquee-container">
      <div className="marquee">
        {texts?.map((text, index) => (
          <span key={index}>{text}</span>
        ))}
      </div>
    </div>
  );
};


export default Ads;