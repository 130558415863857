// import { Button } from "@material-ui/core";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";
import styles from "../../styles/styles";
import { IoCloseCircleSharp } from "react-icons/io5";
import {
  createCommissions,
  deleteCommission,
  getAllCommission,
} from "../../redux/actions/commission";

const AllCommissions = () => {
  const dispatch = useDispatch();
  const { commissionlist = [] } = useSelector((state) => state.commissions);
  const [commId, setCommId] = useState("");
  const [open, setOpen] = useState(false);
  const [views, setViews] = useState(false);
  const [commissionfield, setCommissionfield] = useState({
    title: "",
    rate: "",
    amount: "",
  });

  useEffect(() => {
    dispatch(getAllCommission());
  }, [dispatch]);

  const handleDelete = async (id) => {
    dispatch(deleteCommission(id));
  };

  const handleCollectionformSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", commissionfield.title);
    formData.append("rate", commissionfield.rate);
    formData.append("amount", commissionfield.amount);
    dispatch(createCommissions(formData));
    setCommissionfield({ title: "", rate: "", amount: "" });
    setViews(!views);
  };

  const columns = [
    { field: "sn", headerName: "#SN", minWidth: 60, flex: 0.2 },
    { field: "id", headerName: "Comm. Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Comm. Label",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },
    {
      field: "Edit",
      flex: 0.6,
      minWidth: 100,
      headerName: "Edit",
      type: "",
      renderCell: (params) => {
        return (
          <div className="flex items-start gap-2">
            <Button
              onClick={() => {
                setCommId(params.id);
                // setOpen(true);
              }}
            >
              <AiOutlineEdit size={20} />
            </Button>
          </div>
        );
      },
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 100,
      headerName: "Delete",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() => {
                setCommId(params.id);
                setOpen(true);
              }}
            >
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = commissionlist?.map((item, index) => ({
    sn: index + 1,
    id: item._id,
    name: item.title,
    rate: item.rate,
    amount: "XOF " + item.amount,
  }));

  const handleAddNewCommission = () => {
    setViews(!views);
  };

  const handleCloseModal = () => {
    setViews(!views);
  };

  const handleCommissionChange = (e) => {
    const { name, value } = e.target;
    setCommissionfield({
      ...commissionfield,
      [name]: value,
    });
  };

  return (
    <div className="w-full h-full flex justify-center pt-5 relative">
      <div className="w-[97%] ml-2">
        <h3 className="text-[16px] font-[800]  pb-2">All Commissions</h3>
        <div className="w-full min-h-[45vh] bg-white rounded">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />

          <div className="w-full p-2 flex items-center justify-end">
            <button
              className="text-[white] text-[12px] bg-[green] font-[600] p-3"
              onClick={handleAddNewCommission}
            >
              CREATE COMMISSIONS & FEES
            </button>
          </div>
        </div>

        {views && (
          <div className="w-full bg-[#00000029] h-full flex justify-center items-center fixed top-0 left-0 z-50">
            <div className="flex max-w-[600px] flex-col gap-3 items-center bg-[white] w-[80%] p-5 py-7 min-h-[200px] pb-[50px]">
              <div className="w-full flex items-center justify-center relative">
                <h5 className="text-[20px] font-[600] text-center">
                  Create Commissions
                </h5>

                <IoCloseCircleSharp
                  size={26}
                  color="#002D62"
                  onClick={handleCloseModal}
                  className="cursor-pointer absolute right-0"
                />
              </div>

              <form
                onSubmit={handleCollectionformSubmit}
                className="flex flex-col gap-3 text-[13px] w-[80%] min-w-[300px]"
              >
                <div className="flex gap-2 w-full">
                  <div className="w-full">
                    <label className="pb-2">
                      Commission Title<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="title"
                      required
                      value={commissionfield.title}
                      className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      onChange={(e) => handleCommissionChange(e)}
                      placeholder="Admin Fee"
                    />
                  </div>
                </div>

                <div className="flex gap-2 w-full">
                  <div className="w-full">
                    <label className="pb-2">
                      Commission Rate <span className="text-red-500">(%)</span>
                    </label>
                    <input
                      type="number"
                      name="rate"
                      value={commissionfield.rate}
                      className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      onChange={(e) => handleCommissionChange(e)}
                      placeholder="3 %"
                    />
                  </div>
                </div>

                <div className="flex gap-2 w-full">
                  <div className="w-full">
                    <label className="pb-2">
                      Commission Amount <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      name="amount"
                      value={commissionfield.amount}
                      className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      onChange={(e) => handleCommissionChange(e)}
                      placeholder=""
                    />
                  </div>
                </div>

                <div className="flex gap-2 w-full">
                  <div className="w-full">
                    <input
                      type="submit"
                      name="submit"
                      value="SUBMIT"
                      className="mt-2 cursor-pointer hover:bg-[#002D62] bg-[#002e6253] text-white appearance-none block w-full p-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}

        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[13px] text-center py-5  text-[#000000]">
                Are you sure you want to delete this Order?
              </h3>

              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[13px] !h-[42px] mr-4`}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </div>

                <div
                  className={`${styles.button} text-white text-[13px] !h-[42px] ml-4`}
                  onClick={() => {
                    setOpen(false);
                    handleDelete(commId);
                  }}
                >
                  Confirm
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllCommissions;
