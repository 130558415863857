import React from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";
import AllEvents from "../components/Admin/AllEvents";
import Loader from "../components/Loading/Loading";
import { useSelector } from "react-redux";

const AdminDashboardEvents = () => {
  const { isloading } = useSelector((state) => state.events);
  return (
    <div className="w-full overflow-x-auto h-[100vh] relative">
      <AdminHeader />
      {isloading > 0 && (
        <Loader
          type="bars"
          color="blue"
          text={
            isloading === 1
              ? "Removing Orders"
              : isloading === 2
              ? "Fetching Orders"
              : isloading === 3 && "Updating Order"
          }
          height="30%"
          width="80%"
        />
      )}

      <div className="w-full flex">
        <div className="flex items-start justify-between w-full">
          <div className="w-[50px] 800px:w-[300px] ">
            <AdminSideBar active={6} />
          </div>
          <AllEvents />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardEvents;
