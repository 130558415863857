import React, { useState, useEffect } from "react";
import "./ProductPreview.css";
import Storetopbox from "../../components/Storenavbar/Storetopbox";
import ItemPreview from "./ItemPreview";
import Productdetails from "../../components/Productdetails/Produectdetails";
// import Addedtocartmodal from '../../components/Addedtocartmodal/Addedtocartmodal'
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import SuggestedProduct from "../../components/Products/SuggestedProduct";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import Ratings from "../../components/Products/Ratings";
import Header from "../../components/Layout/Header/Header";
import DeliveryinformationCard from "../../components/DeliveryinformationCard/DeliveryinformationCard";
import Footer from "../../components/Layout/Footer";
import { SplitDate } from "../../components/Productdetails/DateScript";
import SimilarProducts from "../../components/Products/Similarproducts";

import reviews from "./reviews.json";
import Ratingstarbox from "../../components/Ratingstarbox";

const ProductPreview = () => {
  const { products } = useSelector((state) => state.products);
  const { allProducts } = useSelector((state) => state.products);
  const { allEvents } = useSelector((state) => state.events);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();
  const eventData = searchParams.get("isEvent");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (eventData !== null) {
      const data = allEvents && allEvents.find((i) => i._id === id);
      setData(data);
    } else {
      const data = allProducts && allProducts.find((i) => i._id === id);
      setData(data);
    }
  }, [allProducts, allEvents, id, eventData]);

  // console.log(data);

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews?.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg = totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);

  return (
    <div className="bg-[#fff] w-full min-w-[900px]">
      <Header />

      {data ? (
        <div className="ProductPreview-container">
          <Storetopbox storedata={data} />

          <div className="product-body gap-[20px] mx-[50px]">
            <div className="top-preview-container relative">
              <div className="w-[75%] h-full flex flex-col gap-4">
                <div className="product-preview h-[100%] w-full bg-[white] rounded-xl py-4 px-2 ">
                  {data && <ItemPreview dataImages={data.images} />}
                  <Productdetails productdata={data} />
                </div>

                <ProductDetailsInfo
                  data={data}
                  products={products}
                  totalReviewsLength={totalReviewsLength}
                  averageRating={averageRating}
                />
              </div>

              <div className="sticky top-0 w-[25%] h-fit">
                <DeliveryinformationCard productdata={data} />
              </div>
            </div>

            {/* Related Product from Selller */}
            {!eventData && (
              <>{data && <SuggestedProduct productdata={data} />}</>
            )}

            {/* Related You may also like - Products with similar categories */}
            {
              <>
                {data && (
                  <SimilarProducts
                    productdata={data}
                    title="You may also like"
                  />
                )}
              </>
            }
          </div>
          <br />
        </div>
      ) : null}

      <Footer />
    </div>
  );
};

// Ratings breakdown calculation
const getRatingsBreakdown = (reviews) => {
  const breakdown = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
  reviews.forEach(({ rating }) => {
    breakdown[rating] = (breakdown[rating] || 0) + 1;
  });
  return breakdown;
};

// Modal component
const Modal = ({ reviews, closeModal }) => {
  const totalReviews = reviews.length;
  const breakdown = getRatingsBreakdown(reviews);
  const [startabs, setStartabs] = useState({
    tab1: false,
    tab2: false,
    tab3: false,
    tab4: false,
    tab5: false,
    tab0: true,
  });

  const [tab, setTab] = useState(0);

  const handleStartab = (selectedTab) => {
    setStartabs((prevTabs) => {
      const newTabs = {};
      Object.keys(prevTabs).forEach((tab) => {
        newTabs[tab] = tab === selectedTab;
      });
      return newTabs;
    });

    // Extract the figure from the selectedTab string
    const tabNumber = parseInt(selectedTab.match(/\d+/)[0], 10);

    setTab(tabNumber); // Keep the number extracted from selectedTab as a number
  };

  // Calculate average rating
  const averageRating =
    reviews.reduce((acc, { rating }) => acc + rating, 0) / totalReviews;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white flex flex-col w-3/4 min-h-[50vh] max-h-[80vh] gap-4 p-4 rounded-lg overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Ratings & Reviews</h2>
          <button className="text-lg font-bold" onClick={closeModal}>
            &times;
          </button>
        </div>

        <div className="w-full rounded-lg flex flex-col items-center">
          <div className="flex w-full gap-3 justify-between">
            {/* Overall Rating Section */}
            <div className="flex-1 p-4 bg-[#c7c7c788] h-[150px] rounded-md">
              <div className="flex items-center">
                <h1 className="text-5xl font-bold">
                  {averageRating.toFixed(1)}
                </h1>
                <div className="flex items-center ml-2">
                  <div className="flex">
                    {Array.from({ length: 5 }, (_, index) => (
                      <span
                        key={index}
                        className={`text-2xl ${
                          index < Math.round(averageRating)
                            ? "text-yellow-400"
                            : "text-gray-400"
                        }`}
                      >
                        ★
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <p className="text-green-700 mt-2 text-[12px]">
                All from verified purchases
              </p>
            </div>

            {/* Ratings Breakdown */}
            <div className="flex-1 p-4 bg-[#c7c7c788] rounded-md">
              {Object.entries(breakdown)
                .reverse()
                .map(([stars, count]) => (
                  <div key={stars} className="flex items-center  gap-3">
                    <div className="flex items-center w-1/4">
                      {Array.from({ length: 5 }, (_, i) => (
                        <span
                          key={i}
                          className={`text-l ${
                            i < stars ? "text-yellow-400" : "text-gray-400"
                          }`}
                        >
                          ★
                        </span>
                      ))}
                    </div>

                    <div className="w-2/4 bg-gray-300 h-2 rounded-full overflow-hidden">
                      <div
                        className="bg-black h-full rounded-full"
                        style={{
                          width: `${(count / totalReviews) * 100}%`,
                        }}
                      ></div>
                    </div>

                    <div className="w-1/4 text-[12px]">{count}</div>
                  </div>
                ))}
            </div>
          </div>

          {/* Review Filters */}
          <div className="flex flex-wrap mt-4">
            <Ratingstarbox
              star="tab0"
              active={startabs.tab0}
              reviews={`All(${totalReviews})`}
              tab={handleStartab}
            />

            <Ratingstarbox
              star="tab5"
              active={startabs.tab5}
              reviews={`5 stars (${breakdown[5]})`}
              tab={handleStartab}
            />

            <Ratingstarbox
              star="tab4"
              active={startabs.tab4}
              reviews={`4 stars (${breakdown[4]})`}
              tab={handleStartab}
            />

            <Ratingstarbox
              star="tab3"
              active={startabs.tab3}
              reviews={`3 stars (${breakdown[3]})`}
              tab={handleStartab}
            />

            <Ratingstarbox
              star="tab2"
              active={startabs.tab2}
              reviews={`2 stars (${breakdown[2]})`}
              tab={handleStartab}
            />

            <Ratingstarbox
              star="tab1"
              active={startabs.tab1}
              reviews={`1 stars (${breakdown[1]})`}
              tab={handleStartab}
            />
          </div>
        </div>

        <div className="w-full gap-3 flex items-center justify-between flex-wrap">
          {reviews
            .filter((filtereditems) =>
              tab === 0
                ? filtereditems.rating !== tab
                : filtereditems.rating === tab
            )
            .map((item, index) => (
              <div
                key={index}
                className="min-w-[180px] w-[45%] flex-grow flex gap-3 items-center border p-2 rounded-md"
              >
                <img
                  src={item.user.avatar}
                  alt=""
                  className="w-[40px] h-[40px] rounded-full"
                />
                <div className="flex flex-col gap-1">
                  <div className="flex gap-1 items-center">
                    <h1 className="text-[13px] font-[600]">{item.name}</h1>
                    <Ratings rating={item.rating} />
                  </div>
                  <p className="text-[13px]">{item.comment}</p>
                  <p className="text-[13px]">
                    <SplitDate dateData={item.createdAt} />
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
}) => {
  const [active, setActive] = useState(1);
  const [showModal, setShowModal] = useState(false);
  // Close the modal
  const closeModal = () => setShowModal(false);

  return (
    <div className="w-full h-full flex flex-col gap-4 rounded-xl">
      <div className="w-full flex flex-col border-b pt-2 bg-[white] rounded-[10px] overflow-hidden pb-2">
        <div className="w-full flex justify-between border-b pb-2 px-4">
          <h5
            className={
              "text-[#000] text-[16px] py-3 leading-5 font-[600] cursor-pointer"
            }
            onClick={() => setActive(1)}
          >
            Product Details
          </h5>
        </div>

        <div
          className="w-full p-4 rounded-lg text-[13px]"
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      </div>

      <div className=" grow w-full flex flex-col border-b pt-2 bg-[white] rounded-[10px] overflow-hidden pb-2">
        <div className="w-full flex justify-between border-b pb-2 px-4">
          <h5
            className={
              "text-[#000] text-[16px] py-3 leading-5 font-[600] cursor-pointer"
            }
            onClick={() => setActive(1)}
          >
            Verified Customer Review
          </h5>
        </div>

        <div className="w-full min-h-[10vh] flex flex-col items-center py-3 overflow-y-auto">
          <div className="w-full flex flex-wrap gap-3 p-2 my-2">
            {data &&
              data.reviews?.slice(0, 4).map((item, index) => (
                <div
                  key={index}
                  className="min-w-[150px] w-[40%] flex-grow flex gap-3 items-center border px-3 py-2 text-[13px]"
                >
                  <img
                    src={item.user.avatar}
                    alt=""
                    className="w-[40px] h-[40px] rounded-full"
                  />
                  <div className="flex flex-col gap-1">
                    <div className="w-full flex gap-1 items-center">
                      <h1 className="text-[13px] font-[600]">
                        {item.user.name}
                      </h1>
                      <Ratings rating={data?.ratings} />
                    </div>
                    <p className="text-[13px]">{item.comment}</p>
                    <p className="text-[13px]">
                      <SplitDate dateData={item.createdAt} />
                    </p>
                  </div>
                </div>
              ))}
          </div>

          <div className="w-full flex justify-center">
            {reviews && reviews === undefined ? (
              <h5 className="text-[13px]">No Reviews have for this product!</h5>
            ) : data.reviews?.length === 0 ? (
              <h5 className="text-[13px]">No Reviews have for this product!</h5>
            ) : (
              <></>
            )}
          </div>

          {data?.reviews?.length > 4 && (
            <span
              className="p-2 rounded-full text-[12px] font-bold bg-[#ececec] cursor-pointer"
              onClick={() => setShowModal(true)}
            >
              View More
            </span>
          )}

          {showModal && (
            <Modal reviews={data.reviews} closeModal={closeModal} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductPreview;
