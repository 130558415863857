import React from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";
import AllOrders from "../components/Admin/AllOrders";
import { useSelector } from "react-redux";
import Loader from "../components/Loading/Loading";

const AdminDashboardOrders = () => {
  const { adminOrderLoading } = useSelector((state) => state.order);

  return (
    <div className="w-full overflow-x-auto">
      <AdminHeader />

      {adminOrderLoading > 0 && (
        <Loader
          type="bars"
          color="blue"
          text={
            adminOrderLoading === 1
              ? "Removing Orders"
              : adminOrderLoading === 2
              ? "Fetching Orders"
              : adminOrderLoading === 3 && "Updating Order"
          }
          height="30%"
          width="80%"
        />
      )}

      <div className="w-full flex">
        <div className="flex items-start justify-between w-full">
          <div className="w-[50px] h-screen 800px:w-[300px]">
            <AdminSideBar active={2} />
          </div>

          <AllOrders />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardOrders;
