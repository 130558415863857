import React from "react";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";
import CreateProduct from "../../components/Shop/CreateProduct";
import { useSelector } from "react-redux";
import Loader from "../../components/Loading/Loading";

const ShopCreateProduct = () => {
  const { isLoading } = useSelector((state) => state.products);

  return (
    <div className="w-full h-[100vh] flex flex-col relative">
      <DashboardHeader />
      {/* <div className="bg-black w-full h-full fixed top-0 z-10">c</div> */}

      {isLoading && (
        <Loader
          type="bars"
          color="blue"
          text="Adding Product"
          height="30%"
          width="80%"
        />
      )}
      <div className="flex bg-[#8B72BE] justify-between relative w-full">
        <div className="w-[80px] sticky 800px:w-[330px]">
          <DashboardSideBar active={4} />
        </div>
        <div className="w-full justify-center bg-[#f9f9f9] m-1 overflow-y-auto flex">
          <CreateProduct />
        </div>
      </div>
    </div>
  );
};

export default ShopCreateProduct;
