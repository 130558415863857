import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";
import Loader from "../../components/Loading/Loading";

const ShopForgotpassword = () => {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      await axios.post(
        `${server}/shop/reset-password`,
        { email },
        { withCredentials: true }
      );
      setLoader(false);
      toast.success(
        "Password reset link has been sent to your email. Please check your inbox."
      );
    } catch (error) {
      setLoader(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Reset your password
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="relative flex justify-center items-center">
              <button
                disabled={loader === true ? true : false}
                type="submit"
                className="group relative w-full h-[40px] flex justify-center px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 items-center"
              >
                {loader && (
                  <Loader type="spin" color="white" height="40%" width="40%" />
                )}
                Send reset link
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopForgotpassword;
