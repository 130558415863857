import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteProductAdmin,
  getAllProducts,
  updateProduct,
} from "../../redux/actions/product";
import CustomSlider from "../Customslider";
import { FaRegSave } from "react-icons/fa";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";

const AllProducts = () => {
  const dispatch = useDispatch();
  const [sliderValue, setSliderValue] = useState({
    id: 0,
    value: 9,
  });

  const [productId, setProductId] = useState(0);
  const [open, setOpen] = useState(false);

  const handleSliderChange = (props, newValue) => {
    setSliderValue({ ...sliderValue, id: props, value: newValue });
  };

  const handleSubmit = () => {
    dispatch(updateProduct(sliderValue));
  };

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  const { allProducts = [] } = useSelector((state) => state.products);

  const handleDelete = (id) => {
    dispatch(deleteProductAdmin(id));
  };

  const columns = [
    { field: "sn", headerName: "#SN", minWidth: 60, flex: 0.2 },
    { field: "id", headerName: "Product Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 130,
      flex: 1.2,
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 100,
      flex: 0.4,
    },
    {
      field: "Stock",
      headerName: "Stock",
      type: "number",
      minWidth: 80,
      flex: 0.4,
    },
    {
      field: "sold",
      headerName: "Sold out",
      type: "number",
      minWidth: 80,
      flex: 0.4,
    },
    {
      field: "Preview",
      flex: 0.4,
      minWidth: 100,
      headerName: "Preview",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/products/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "visibility",
      flex: 0.5,
      minWidth: 100,
      headerName: "Visibility",
      type: "",
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <CustomSlider
              onSliderChange={(value) => handleSliderChange(params.id, value)}
              currentstate={params?.row?.visibility}
            />
          </div>
        );
      },
    },
    {
      field: "Delete",
      flex: 0.4,
      minWidth: 100,
      headerName: "Delete",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() => {
                setProductId(params.id);
                setOpen(true);
              }}
            >
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
    {
      field: "Save",
      flex: 0.4,
      minWidth: 100,
      headerName: "Save",
      type: "",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={handleSubmit}>
              <FaRegSave size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = allProducts?.map((item, index) => ({
    sn: index + 1,
    id: item._id,
    name: item.name,
    price: "XOF  " + item.discountPrice,
    Stock: item.stock,
    sold: item?.sold_out,
    visibility: item?.visibility,
  }));

  return (
    <div className="w-full h-full flex justify-center pt-5 relative">
      <div className="w-[97%] ml-2">
        <h3 className="text-[16px] font-[800]  pb-2">All Products</h3>
        <div className="w-full min-h-[45vh] bg-white rounded">
          {allProducts ? (
            <DataGrid
              rows={row}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              rowHeight={100}
              slots={{ toolbar: GridToolbar }}
            />
          ) : (
            <p>Loading products...</p>
          )}
        </div>
      </div>

      {open && (
        <div className="w-full fixed top-0 left-0 z-50 bg-[#00000039] flex items-center justify-center h-screen">
          <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
            <div className="w-full flex justify-end cursor-pointer">
              <RxCross1 size={25} onClick={() => setOpen(false)} />
            </div>
            <h3 className="text-[15px] text-[700] text-center">
              Are you sure you want to delete this Product?
            </h3>

            <div className="w-full flex items-center justify-center">
              <div
                className={`${styles.button} text-white text-[13px] !h-[42px] mr-4`}
                onClick={() => setOpen(false)}
              >
                Cancel
              </div>

              <div
                className={`${styles.button} text-white text-[13px] !h-[42px] ml-4`}
                onClick={() => {
                  setOpen(false);
                  handleDelete(productId);
                }}
              >
                Confirm
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllProducts;
