// actionTypes.js
export const GET_COUPONS_REQUEST = "GET_COUPONS_REQUEST";
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";
export const GET_COUPONS_FAIL = "GET_COUPONS_FAIL";
export const CREATE_COUPON_REQUEST = "CREATE_COUPON_REQUEST";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAIL = "CREATE_COUPON_FAIL";
export const DELETE_COUPON_REQUEST = "DELETE_COUPON_REQUEST";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_FAIL = "DELETE_COUPON_FAIL";
