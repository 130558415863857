// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { useSearchParams } from "react-router-dom";
// import Footer from "../components/Layout/Footer";
// import Header from "../components/Layout/Header/Header";
// import Loader from "../components/Layout/Loader";
// import ProductCard from "../components/Route/ProductCard/ProductCard";
// import styles from "../styles/styles";

// const ProductsPage = () => {
//   const [searchParams] = useSearchParams();
//   const categoryData = searchParams.get("category");
//   const { allProducts, isLoading } = useSelector((state) => state.products);
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     if (categoryData === null) {
//       const d = allProducts;
//       setData(d);
//     } else {
//       const d =
//         allProducts && allProducts.filter((i) => i.category === categoryData);
//       setData(d);
//     }
//     //    window.scrollTo(0,0);
//   }, [allProducts]);

//   return (
//     <>
//       {isLoading ? (
//         <Loader />
//       ) : (
//         <div>
//           <Header activeHeading={3} />
//           <br />
//           <br />
//           <div className={`${styles.section}`}>
//             <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
//               {data &&
//                 data?.map((i, index) => <ProductCard data={i} key={index} />)}
//             </div>
//             {data && data?.length === 0 ? (
//               <h1 className="text-center w-full pb-[100px] text-[20px]">
//                 No products Found!
//               </h1>
//             ) : null}
//           </div>
//           <Footer />
//         </div>
//       )}
//     </>
//   );
// };

// export default ProductsPage;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header/Header";
import Loader from "../components/Layout/Loader";
// import ProductCard from "../components/Route/ProductCard/ProductCard";
// import styles from "../styles/styles";
import Collectioncard from "../components/Cards/CollectionCard/CollectionCard1";
import FilterProduct from "../components/Productfilter/Productfilter";
import SortDropdown from "../components/Sortdropdown/Sortdropdown";

const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  let type = false;

  useEffect(() => {
    if (categoryData === null) {
      const d = allProducts;
      setData(d);
    } else {
      const d =
        allProducts && allProducts.filter((i) => i.category === categoryData);
      setData(d);
    }
    window.scrollTo(0, 0); // Ensures the page starts at the top on category change
  }, [allProducts, categoryData]);

  let categories = data?.map((items) => items?.category);

  return (
    <div className="lndpgContainer bg-[#F0F8FF] gap-7">
      <Header activeHeading={3} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full flex flex-col items-center">
          <div className="w-[90%] flex flex-col gap-2">
            <div className="w-full flex items-center text-[13px] gap-2">
              <span>Home</span>
              &#62;
              <span>All Products</span>
            </div>
            <div className="w-full flex items-top gap-2 justify-center">
              <div className="w-fit bg-[white]">
                <FilterProduct categories={categories} />
              </div>

              <div className="w-full flex flex-col p-2 bg-[white]">
                <div className="w-full flex justify-between p-3">
                  <div className="text-sm flex gap-1 border-b-[#00000060]">
                    <span className="font-bold text-[14px]">
                      Shop Online in Mali
                    </span>
                    <span className="text-[#00000060]">
                      ({data?.length} products found)
                    </span>
                  </div>

                  <div className="flex gap-2 items-center">
                    {/* <span className="text-sm">Sort by:</span> */}
                    <SortDropdown />
                  </div>
                </div>
                <div className="w-full flex-wrap gap-1">
                  {/* <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12 bg-[aqua]"> */}
                  <div className="flex flex-wrap gap-2">
                    {/* {data &&
                        data?.map((i, index) => <ProductCard data={i} key={index} />)} */}
                    {data &&
                      data?.map((i, index) => (
                        <Collectioncard type={type} data={i} key={index} />
                      ))}
                  </div>
                  {data && data?.length === 0 ? (
                    <h1 className="text-center w-full pb-[100px] text-[20px]">
                      No products Found!
                    </h1>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default ProductsPage;
