import React, { useEffect, useState } from "react";
import { AiFillCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import { createevent } from "../../redux/actions/event";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorToolbar, {
  modules,
  formats,
} from "../../components/EditorToolbar";

const CreateEvent = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.events);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (e) => {
    const startDate = new Date(e.target.value);
    const minEndDate = new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000);
    setStartDate(startDate);
    setEndDate(null);
    document.getElementById("end-date").min = minEndDate.toISOString.slice(
      0,
      10
    );
  };

  const handleEndDateChange = (e) => {
    const endDate = new Date(e.target.value);
    setEndDate(endDate);
  };

  const today = new Date().toISOString().slice(0, 10);

  const minEndDate = startDate
    ? new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10)
    : "";

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Event created successfully!");
      navigate("/dashboard-events");
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.append("images", image);
    });
    const data = {
      name,
      description: value,
      category,
      tags,
      originalPrice,
      discountPrice,
      stock,
      images,
      shopId: seller._id,
      start_Date: startDate?.toISOString(),
      Finish_Date: endDate?.toISOString(),
    };
    dispatch(createevent(data));
  };

  const handleReduceImage = (imgitem, imageIndex) => {
    const filteredImage = images.filter(
      (currentItem) => images.indexOf(currentItem) !== imageIndex
    );
    setImages(filteredImage);
  };

  return (
    <div className="w-[90%] 800px:w-[90%] shadow mx-8 my-10 p-3 py-7 bg-white overflow-y-auto flex flex-col gap-4">
      <h5 className="text-[20px] font-[600] text-center">Create Event</h5>
      {/* create event form */}
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div className="flex gap-2">
          <div className="w-full flex flex-col gap-1">
            <label className="text-[14px] font-[500]">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={name}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your event product name..."
            />
          </div>

          <div className="w-full flex flex-col gap-1">
            <label className="text-[14px] font-[500]">
              Category <span className="text-red-500">*</span>
            </label>
            <select
              className="w-full mt-2 border h-[35px] rounded-[5px]"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="Choose a category">Choose a category</option>
              {categoriesData &&
                categoriesData?.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[500]">
            Description <span className="text-red-500">*</span>
          </label>

          <div className="w-full h-full">
            <EditorToolbar toolbarId={"t2"} />
            <ReactQuill
              theme="snow"
              value={value}
              onChange={setValue}
              className="w-full h-[200px] overflow-y-auto"
              modules={modules("t2")}
              formats={formats}
            />
          </div>
        </div>

        <div className="flex gap-2">
          <div className="w-full flex flex-col gap-1">
            <label className="text-[14px] font-[500]">Tags</label>
            <input
              type="text"
              name="tags"
              value={tags}
              className="appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setTags(e.target.value)}
              placeholder="Enter your event product tags..."
            />
          </div>

          <div className="w-full flex flex-col gap-1">
            <label className="text-[14px] font-[500]">
              Product Stock <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="price"
              value={stock}
              className="appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setStock(e.target.value)}
              placeholder="Enter your event product stock..."
            />
          </div>
        </div>

        <div className="flex gap-2">
          <div className="w-full flex flex-col gap-1">
            <label className="text-[14px] font-[500]">Original Price</label>
            <input
              type="number"
              name="price"
              value={originalPrice}
              className="appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setOriginalPrice(e.target.value)}
              placeholder="Enter your event product price..."
            />
          </div>

          <div className="w-full flex flex-col gap-1">
            <label className="text-[14px] font-[500]">
              Price (With Discount) <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="price"
              value={discountPrice}
              className="appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setDiscountPrice(e.target.value)}
              placeholder="Enter your event product price with discount..."
            />
          </div>
        </div>

        <div className="flex gap-2">
          <div className="w-full flex flex-col gap-1">
            <label className="text-[14px] font-[500]">
              Event Start Date <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              name="price"
              id="start-date"
              value={startDate ? startDate.toISOString().slice(0, 10) : ""}
              className="appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={handleStartDateChange}
              min={today}
              placeholder="Enter your event product stock..."
            />
          </div>

          <div className="w-full flex flex-col gap-1">
            <label className="text-[14px] font-[500]">
              Event End Date <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              name="price"
              id="start-date"
              value={endDate ? endDate.toISOString().slice(0, 10) : ""}
              className="appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={handleEndDateChange}
              min={minEndDate}
              placeholder="Enter your event product stock..."
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[500]">
            Upload Images <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {images &&
              images?.map((i, index) => (
                <div className="picture_frame_container" key={index}>
                  <img
                    src={i}
                    key={i}
                    alt=""
                    className="h-[50px] w-[50px] object-cover m-2"
                  />
                  <AiFillCloseCircle
                    id="close-icon"
                    onClick={() => handleReduceImage(i, index)}
                  />
                </div>
              ))}
          </div>
          <br />
          <div>
            <input
              type="submit"
              value="CREATE"
              className="mt-2 cursor-pointer appearance-none text-center block w-full p-2 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-[white] bg-[blue]"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateEvent;
