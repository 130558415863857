import React from "react";
import { FiShoppingBag } from "react-icons/fi";
import { GrWorkshop } from "react-icons/gr";
import { RxDashboard } from "react-icons/rx";
import { CiMoneyBill } from "react-icons/ci";
import { Link } from "react-router-dom";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsHandbag } from "react-icons/bs";
import { MdOutlineLocalOffer } from "react-icons/md";
import { AiOutlineSetting } from "react-icons/ai";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { GiMoneyStack } from "react-icons/gi";

const AdminSideBar = ({ active }) => {
  return (
    <div className="w-full h-full bg-[white] px-1 py-3 flex flex-col gap-1 shadow-sm overflow-y-auto z-10">
      {/* single item */}
      <div
        className={`w-full flex hover:bg-border-color rounded-md border ${
          active === 1 && "border border-gray-600"
        } items-center px-4 py-4`}
      >
        <Link to="/admin/dashboard" className="w-full flex items-center">
          <RxDashboard
            size={25}
            color={`${active === 1 ? "crimson" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-4 text-[14px] font-[400] ${
              active === 1 ? "text-[crimson]" : "text-[#555]"
            }`}
          >
            Dashboard
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-border-color rounded-md border ${
          active === 2 && "border border-gray-600"
        } items-center px-4 py-4`}
      >
        <Link to="/admin-orders" className="w-full flex items-center">
          <FiShoppingBag
            size={25}
            color={`${active === 2 ? "crimson" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-4 text-[14px] font-[400] ${
              active === 2 ? "text-[crimson]" : "text-[#555]"
            }`}
          >
            All Orders
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-border-color rounded-md border ${
          active === 3 && "border border-gray-600"
        } items-center px-4 py-4`}
      >
        <Link to="/admin-sellers" className="w-full flex items-center">
          <GrWorkshop
            size={25}
            color={`${active === 3 ? "crimson" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-4 text-[14px] font-[400] ${
              active === 3 ? "text-[crimson]" : "text-[#555]"
            }`}
          >
            All Sellers
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-border-color rounded-md border ${
          active === 4 && "border border-gray-600"
        } items-center px-4 py-4`}
      >
        <Link to="/admin-users" className="w-full flex items-center">
          <HiOutlineUserGroup
            size={25}
            color={`${active === 4 ? "crimson" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-4 text-[14px] font-[400] ${
              active === 4 ? "text-[crimson]" : "text-[#555]"
            }`}
          >
            All Users
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-border-color rounded-md border ${
          active === 9 && "border border-gray-600"
        } items-center px-4 py-4`}
      >
        <Link to="/admin-categories" className="w-full flex items-center">
          <BiSolidCategoryAlt
            size={25}
            color={`${active === 9 ? "crimson" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-4 text-[14px] font-[400] ${
              active === 9 ? "text-[crimson]" : "text-[#555]"
            }`}
          >
            Categories
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-border-color rounded-md border ${
          active === 5 && "border border-gray-600"
        } items-center px-4 py-4`}
      >
        <Link to="/admin-products" className="w-full flex items-center">
          <BsHandbag size={25} color={`${active === 5 ? "crimson" : "#555"}`} />
          <h5
            className={`hidden 800px:block pl-4 text-[14px] font-[400] ${
              active === 5 ? "text-[crimson]" : "text-[#555]"
            }`}
          >
            All Products
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-border-color rounded-md border ${
          active === 6 && "border border-gray-600"
        } items-center px-4 py-4`}
      >
        <Link to="/admin-events" className="w-full flex items-center">
          <MdOutlineLocalOffer
            size={25}
            color={`${active === 6 ? "crimson" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-4 text-[14px] font-[400] ${
              active === 6 ? "text-[crimson]" : "text-[#555]"
            }`}
          >
            All Events
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-border-color rounded-md border ${
          active === 10 && "border border-gray-600"
        } items-center px-4 py-4`}
      >
        <Link
          to="/admin-fees-and-comissions"
          className="w-full flex items-center"
        >
          <GiMoneyStack
            size={25}
            color={`${active === 10 ? "crimson" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-4 text-[14px] font-[400] ${
              active === 10 ? "text-[crimson]" : "text-[#555]"
            }`}
          >
            Fees & Cmomission
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-border-color rounded-md border ${
          active === 7 && "border border-gray-600"
        } items-center px-4 py-4`}
      >
        <Link to="/admin-withdraw-request" className="w-full flex items-center">
          <CiMoneyBill
            size={25}
            color={`${active === 7 ? "crimson" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-4 text-[14px] font-[400] ${
              active === 7 ? "text-[crimson]" : "text-[#555]"
            }`}
          >
            Withdraw Request
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-border-color rounded-md border ${
          active === 8 && "border border-gray-600"
        } items-center px-4 py-4`}
      >
        <Link to="/profile" className="w-full flex items-center">
          <AiOutlineSetting
            size={25}
            color={`${active === 8 ? "crimson" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-4 text-[14px] font-[400] ${
              active === 8 ? "text-[crimson]" : "text-[#555]"
            }`}
          >
            Settings
          </h5>
        </Link>
      </div>
    </div>
  );
};

export default AdminSideBar;
