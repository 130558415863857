import React from "react";

const Ratingstarbox = ({ star, reviews, active, tab }) => {
  const handleStarClick = () => {
    tab(star);
  };

  return (
    <button
      onClick={handleStarClick}
      className={`px-3 py-2 text-[13px] m-1 rounded-full ${
        active ? "bg-red-200 text-red-600" : "bg-gray-200"
      }`}
    >
      {reviews}
    </button>
  );
};

export default Ratingstarbox;
