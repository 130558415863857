import axios from "axios";
import { toast } from "react-toastify";
import {
  GET_COUPONS_REQUEST,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAIL,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
} from "../reducers/actionTypes";
import { server } from "../../server";

// Get Coupons
export const getCoupons = (sellerId) => async (dispatch) => {
  try {
    dispatch({ type: GET_COUPONS_REQUEST });

    const { data } = await axios.get(
      `${server}/coupon/get-coupon/${sellerId}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: GET_COUPONS_SUCCESS,
      payload: data.couponCodes,
    });
  } catch (error) {
    dispatch({
      type: GET_COUPONS_FAIL,
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
  }
};

// Create Coupon
export const createCoupon = (couponData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_COUPON_REQUEST });

    const { data } = await axios.post(
      `${server}/coupon/create-coupon-code`,
      couponData,
      { withCredentials: true }
    );

    dispatch({
      type: CREATE_COUPON_SUCCESS,
      payload: data.coupounCodes,
    });

    toast.success("Coupon code created successfully!");
  } catch (error) {
    dispatch({
      type: CREATE_COUPON_FAIL,
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
  }
};

// Delete Coupon
export const deleteCoupon = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_COUPON_REQUEST });

    await axios.delete(`${server}/coupon/delete-coupon/${id}`, {
      withCredentials: true,
    });

    dispatch({
      type: DELETE_COUPON_SUCCESS,
      payload: id,
    });

    toast.success("Coupon code deleted successfully!");
  } catch (error) {
    dispatch({
      type: DELETE_COUPON_FAIL,
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
  }
};
