import React, { useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineStar,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { useEffect } from "react";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";
import "./ProductCard.css";
import NumberFormat from "react-number-format";

const ProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <>
      <div className="w-[180px] max-w-[260px] h-[350px] bg-white rounded-lg shadow-sm p-2 relative cursor-pointer gap-2 flex-grow justify-between">
        <Link
          to={`${
            isEvent === true
              ? `/products/${data._id}?isEvent=true`
              : `/products/${data._id}`
          }`}
        >
          <img
            src={`${data.images && data.images[0]?.url}`}
            alt=""
            className="w-full h-[170px] object-contain"
          />
        </Link>

        <Link to={`/shop/preview/${data?.shop._id}`}>
          <h5 className={`${styles.shop_name}`}>{data.shop.name}</h5>
        </Link>

        <Link
          to={`${
            isEvent === true
              ? `/products/${data._id}?isEvent=true`
              : `/products/${data._id}`
          }`}
          className="flex flex-col gap-3 justify-around"
        >
          <h4 className="text-[12px] font-[500] min-h-[30px]">
            {data.name?.length > 40
              ? data.name.slice(0, 40) + "..."
              : data.name}
          </h4>

          <div className="flex">
            <Ratings rating={data?.ratings} />
          </div>

          <div className="flex-col items-center justify-between">
            <div className="flex justify-between items-center">
              <h5 className={`${styles.productDiscountPrice}`}>
                <NumberFormat
                  value={
                    data.originalPrice === 0
                      ? data.originalPrice
                      : data.discountPrice
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"XOF "}
                />
              </h5>
              <h4 className={`${styles.price}`}>
                <NumberFormat
                  value={data.originalPrice ? data.originalPrice : null}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"XOF "}
                />
              </h4>
            </div>

            <span className="font-[400] text-[12px] text-[#68d284]">
              {data?.sold_out} sold
            </span>
          </div>
        </Link>

        {/* side options */}
        <div className="flex flex-col gap-6 items-center absolute right-2 top-10">
          {click ? (
            <AiFillHeart
              size={18}
              className="cursor-pointer"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={18}
              className="cursor-pointer"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Add to wishlist"
            />
          )}

          <AiOutlineEye
            size={18}
            className="cursor-pointer"
            onClick={() => setOpen(!open)}
            color="#333"
            title="Quick view"
          />

          <AiOutlineShoppingCart
            size={18}
            className="cursor-pointer"
            onClick={() => addToCartHandler(data._id)}
            color="#444"
            title="Add to cart"
          />
          {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
        </div>
      </div>
    </>
  );
};

export default ProductCard;
