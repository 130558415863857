import React from "react";
import ReactLoading from "react-loading";
import "./Loading.css";

const Loader = ({ type, color, text, height, width }) => (
  <div className="bg-custom-gray w-full h-full fixed top-0 z-[100] flex justify-center items-center">
    <div className="flex flex-col justify-center items-center w-[25%] gap-3 max-w-[150px]">
      <ReactLoading type={type} color={color} height={height} width={width} />
      <span className="text-center text-[13px]">{text}</span>
    </div>
  </div>
);

export default Loader;
