import React from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";
import AllSellers from "../components/Admin/AllSellers";
import { useSelector } from "react-redux";
import Loader from "../components/Loading/Loading";

const AdminDashboardSellers = () => {
  const { isloading } = useSelector((state) => state.seller);

  return (
    <div className="w-full overflow-x-auto h-[100vh] relative">
      <AdminHeader />

      {isloading > 0 && (
        <Loader
          type="bars"
          color="blue"
          text={
            isloading === 1
              ? "Removing Seller"
              : isloading === 2
              ? "Fetching Seller"
              : isloading === 3 && "Updating Seller"
          }
          height="30%"
          width="80%"
        />
      )}

      <div className="flex items-start justify-between w-full h-full overflow-y-auto relative">
        <div className="w-[50px] 800px:w-[300px] h-full sticky top-0 left-0">
          <AdminSideBar active={3} />
        </div>
        <AllSellers />
      </div>
    </div>
  );
};

export default AdminDashboardSellers;
