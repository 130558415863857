import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrdersOfAdmin,
  removeUserOrders,
} from "../../redux/actions/order";
import NumberFormat from "react-number-format";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import { Button } from "@mui/material";
import { AiOutlineDelete } from "react-icons/ai";

const AllOrders = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
  }, [dispatch]);

  const { adminOrders = [] } = useSelector((state) => state.order);

  const handleDelete = async (id) => {
    dispatch(removeUserOrders(id));
  };

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.row.status === "Delivered"
          ? "bg-[green] text-[white]"
          : params.row.status === "Processing"
          ? "bg-[red] text-[white]"
          : params.row.status === "Refund Success"
          ? "bg-[teal] text-[white]"
          : "bg-[orange] text-[white]";
      },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "createdAt",
      headerName: "Order Date",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "action",
      flex: 0.4,
      minWidth: 100,
      headerName: "Action",
      sortable: false,
      renderCell: (params) => (
        <>
          <Button
            onClick={() => {
              setOrderId(params.id);
              setOpen(true);
            }}
          >
            <AiOutlineDelete size={20} />
          </Button>
        </>
      ),
    },
  ];

  const row = [];
  adminOrders &&
    adminOrders.forEach((item) => {
      const formattedTotal = (
        <NumberFormat
          value={item?.totalPrice}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"XOF "}
          renderText={(formattedValue) => `XOF ${formattedValue}`} // Function to get the string value with prefix
        />
      );

      row.push({
        id: item._id,
        itemsQty: item?.cart?.reduce((acc, item) => acc + item.qty, 0),
        total: formattedTotal.props.renderText(
          formattedTotal.props.value.toLocaleString()
        ),
        status: item?.status,
        createdAt: item?.createdAt.slice(0, 10),
      });
    });

  return (
    <div className="w-full min-h-[45vh] pt-5 rounded flex justify-center">
      <div className="w-[97%] ml-2">
        <h3 className="text-[16px] font-[800]  pb-2">All Orders</h3>
        <div className="w-full min-h-[45vh] bg-white rounded">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={4}
            disableSelectionOnClick
            autoHeight
            slots={{ toolbar: GridToolbar }}
          />
        </div>

        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[13px] text-center py-5  text-[#000000]">
                Are you sure you want to delete this Order?
              </h3>

              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[13px] !h-[42px] mr-4`}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </div>

                <div
                  className={`${styles.button} text-white text-[13px] !h-[42px] ml-4`}
                  onClick={() => {
                    setOpen(false);
                    handleDelete(orderId);
                  }}
                >
                  Confirm
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllOrders;
