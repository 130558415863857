// import { Button } from "@material-ui/core";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { deleteEventAdmin, getAllEvents } from "../../redux/actions/event";
import { useDispatch, useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";
import styles from "../../styles/styles";

const AllEvents = () => {
  const dispatch = useDispatch();
  const { allEvents = [] } = useSelector((state) => state.events);
  const [eventId, setEventId] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getAllEvents());
  }, [dispatch]);

  const handleDelete = async (id) => {
    dispatch(deleteEventAdmin(id));
  };

  const columns = [
    { field: "sn", headerName: "#SN", minWidth: 60, flex: 0.2 },
    { field: "id", headerName: "Product Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Stock",
      headerName: "Stock",
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },

    {
      field: "sold",
      headerName: "Sold out",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "Preview",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/products/${params.id}?isEvent=true`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "Preview",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() => {
                setEventId(params.id);
                setOpen(true);
              }}
            >
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = allEvents?.map((item, index) => ({
    sn: index + 1,
    id: item._id,
    name: item.name,
    price: "XOF " + item.discountPrice,
    Stock: item.stock,
    sold: item.sold_out,
  }));

  return (
    <div className="w-full h-full flex justify-center pt-5 relative">
      <div className="w-[97%] ml-2">
        <h3 className="text-[16px] font-[800]  pb-2">All Events</h3>
        <div className="w-full min-h-[45vh] bg-white rounded">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>

        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[13px] text-center py-5  text-[#000000]">
                Are you sure you want to delete this Order?
              </h3>

              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[13px] !h-[42px] mr-4`}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </div>

                <div
                  className={`${styles.button} text-white text-[13px] !h-[42px] ml-4`}
                  onClick={() => {
                    setOpen(false);
                    handleDelete(eventId);
                  }}
                >
                  Confirm
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllEvents;
