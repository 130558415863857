import React from 'react'
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader'
import CreateEvent from "../../components/Shop/CreateEvent";
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';

const ShopCreateEvents = () => {
  return (
    <div className="w-full h-[100vh] flex flex-col">
        <DashboardHeader />
        <div className="flex bg-[#89CFF0] justify-between relative w-full">
          <div className="w-[80px] sticky 800px:w-[330px]">
            <DashboardSideBar active={6} />
          </div>
          <div className="w-full justify-center bg-[#f9f9f9] m-1 overflow-y-auto flex">
            <CreateEvent />
          </div>
        </div>
    </div>
  )
}

export default ShopCreateEvents