import React, { useState } from "react";
import "./SortDropdown.css";

const SortDropdown = () => {
  const [selectedOption, setSelectedOption] = useState("Popularity");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    // Add your sorting logic here based on the selected option
  };

  return (
    <div className="sort-dropdown">
      <label className="sort-label">Sort by:</label>
      <select
        className="sort-select"
        value={selectedOption}
        onChange={handleOptionChange}
      >
        <option value="Popularity">Popularity</option>
        <option value="Newest Arrivals">Newest Arrivals</option>
        <option value="Price: Low to High">Price: Low to High</option>
        <option value="Price: High to Low">Price: High to Low</option>
        <option value="Product Rating">Product Rating</option>
      </select>

      {/* <div className="sort-options">
        <label>
          <input
            type="radio"
            value="Popularity"
            checked={selectedOption === "Popularity"}
            onChange={handleOptionChange}
          />
          Popularity
        </label>

        <label>
          <input
            type="radio"
            value="Newest Arrivals"
            checked={selectedOption === "Newest Arrivals"}
            onChange={handleOptionChange}
          />
          Newest Arrivals
        </label>

        <label>
          <input
            type="radio"
            value="Price: Low to High"
            checked={selectedOption === "Price: Low to High"}
            onChange={handleOptionChange}
          />
          Price: Low to High
        </label>

        <label>
          <input
            type="radio"
            value="Price: High to Low"
            checked={selectedOption === "Price: High to Low"}
            onChange={handleOptionChange}
          />
          Price: High to Low
        </label>

        <label>
          <input
            type="radio"
            value="Product Rating"
            checked={selectedOption === "Product Rating"}
            onChange={handleOptionChange}
          />
          Product Rating
        </label>
      </div> */}
    </div>
  );
};

export default SortDropdown;
