import React, { useState } from "react";
import "./Storenavbar.css";
import { BsShop, BsPlus, BsChatQuote } from "react-icons/bs";
import { BiChevronRight } from "react-icons/bi";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { server } from "../../server";
import { useNavigate } from "react-router-dom";

const Storenavbar = ({ storedata }) => {
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = storedata._id + user._id;
      const userId = user._id;
      const sellerId = storedata.shop._id;
      await axios
        .post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        })
        .then((res) => {
          navigate(`/inbox?${res.data.conversation._id}`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("Please login to create a conversation");
    }
  };

  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeaves = () => {
    setHover(false);
  };
  return (
    <div className="storenav-bar-container">
      <div className="shop-info-container" onMouseEnter={handleMouseEnter}>
        <div className="store-icon-box">
          <BsShop id="store-icon" />
        </div>

        <span>{storedata.shop._id}</span>

        <BiChevronRight id="chevron-right-icon" />
      </div>
      {hover && (
        <div
          className="box-bover-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeaves}
        >
          <div className="store-info-container">
            <span>Store info</span>

            {/* <div className='shop-info-labels'>
                    Shop No:	<span>{storedata.shop._id}</span>
                </div> */}

            <div className="shop-info-labels">
              Store No: <span>{storedata.shop._id}</span>
            </div>

            {/* <div className='shop-info-labels'>
                    Location:	<span>{storedata.shop.address}</span>
                </div> */}

            <div className="shop-info-labels">
              Joined On: <span>{storedata.shop?.createdAt?.slice(0, 10)}</span>
            </div>
          </div>

          {/* <div className="store-info-container">
                <span>Seller Rating</span>

                <div className='shop-info-labels'>
                    Item as described:	<span>4.5 Above Average</span>
                </div>

                <div className='shop-info-labels'>
                    Communication:	<span>4.5 Above Average</span>
                </div>

                <div className='shop-info-labels'>
                    Shipping Speed:	<span>4.5 Above Average</span>
                </div>
            </div> */}
        </div>
      )}

      <div className="right-shop-info-container">
        {/* <div className="info-feedback">
          <span>95% </span>
          Positive Feedback
        </div> */}

        {/* <span>|</span> */}

        {/* <div className="info-feedback">
          <span>4304 </span>
          Followers
        </div>

        <div className="folow-container">
          <BsPlus id="plus-icon" />
          Follow
        </div>

        <div className="folow-container" onClick={handleMessageSubmit}>
          <BsChatQuote id="plus-icon" />
          Message
        </div> */}
      </div>
    </div>
  );
};

export default Storenavbar;
