import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  success: false,
  error: false,
  allProducts: [],
};

export const productReducer = createReducer(initialState, {
  productCreateRequest: (state) => {
    state.isLoading = true;
  },
  productCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.product = action.payload;
    state.success = true;
  },
  productCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

  // get all products of shop
  getAllProductsShopRequest: (state) => {
    state.isLoading = true;
  },

  getAllProductsShopSuccess: (state, action) => {
    state.isLoading = false;
    state.products = action.payload;
  },

  getAllProductsShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all products
  getAllProductsAdminRequest: (state) => {
    state.isLoading = true;
  },

  getAllProductsAdminSuccess: (state, action) => {
    state.isLoading = false;
    state.adminproducts = action.payload;
  },

  getAllProductsAdminFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // delete product of a shop
  deleteProductRequest: (state) => {
    state.isLoading = true;
  },
  deleteProductSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
  },
  deleteProductFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // Admin delete product of a shop
  admindeleteProductRequest: (state) => {
    state.isLoading = 1;
  },

  admindeleteProductSuccess: (state, action) => {
    state.isLoading = 0;
    state.allProducts = action.payload;
    state.message = "Product Deleted Successfully";
  },

  admindeleteProductFailed: (state, action) => {
    state.isLoading = 0;
    state.error = action.payload;
  },

  // get all products
  getAllProductsRequest: (state) => {
    state.isLoading = 2;
  },

  getAllProductsSuccess: (state, action) => {
    state.isLoading = 0;
    state.allProducts = action.payload;
  },

  getAllProductsFailed: (state, action) => {
    state.isLoading = 0;
    state.error = action.payload;
  },

  // seller edit/update a product
  editProductRequest: (state, action) => {
    state.isLoading = 3;
  },

  editProductSuccess: (state, action) => {
    state.isLoading = 0;
    state.allProducts = action.payload;
    state.success = true;
  },

  editProductFailed: (state, action) => {
    state.isLoading = 0;
    state.error = action.payload;
    state.success = false;
  },

  // Admin Update visibility of a product
  updateProductRequest: (state) => {
    state.isLoading = 3;
  },

  updateProductSuccess: (state, action) => {
    state.isLoading = 0;
    state.allProducts = action.payload;
  },

  updateProductFail: (state, action) => {
    state.isLoading = 0;
    state.error = action.payload;
  },

  // Action to reset success state
  resetEditProduct: (state) => {
    state.success = false;
    state.error = false; // Optional, in case you also want to reset the error
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
