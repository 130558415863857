import React, { useState } from "react";
import "./Productfilter.css";

const FilterProduct = ({ categories }) => {
  const [priceRange, setPriceRange] = useState({ min: 0, max: 1000 });

  // const categories = [
  //   "Camping",
  //   "Beverages",
  //   "Sporting Goods",
  //   "Travel & Luggage",
  //   "Toys",
  //   "Home & Office",
  //   "Automotive",
  // ];

  const brands = ["Samsung", "LG", "Apple", "Infinix", "Tecno"];
  const sizes = ["S", "M", "L", "XL", "XXL"];

  return (
    <div className="filter-box max-w-[300px]">
      {/* Category Section */}
      <div className="filter-section">
        <h4 className="font-bold text-[14px]">Category</h4>
        <ul>
          {categories?.map((category) => (
            <li key={category}>
              <input type="checkbox" id={category} />
              <label htmlFor={category}>{category}</label>
            </li>
          ))}
        </ul>
      </div>

      {/* Express Delivery */}
      <div className="filter-section">
        <h4 className="font-bold text-[14px]">Express Delivery</h4>
        <input type="checkbox" id="express" />
        <label htmlFor="express">Express Delivery</label>
      </div>

      {/* Shipped From */}
      <div className="filter-section">
        <h4 className="font-bold text-[14px]">Shipped From</h4>
        <div className="w-full flex flex-col gap-2">
          <div className="flex items-center">
            <input type="checkbox" id="abroad" />
            <label htmlFor="abroad">Shipped from Abroad</label>
          </div>

          <div className="flex items-center">
            <input type="checkbox" id="nigeria" />
            <label htmlFor="nigeria">Shipped from Mali</label>
          </div>
        </div>
      </div>

      {/* Price Filter */}
      <div className="filter-section">
        <h4 className="font-bold text-[14px]">Price (₦)</h4>
        <div className="price-inputs">
          <input
            type="number"
            placeholder="Min"
            value={priceRange.min}
            onChange={(e) =>
              setPriceRange({ ...priceRange, min: e.target.value })
            }
          />
          <span> - </span>
          <input
            type="number"
            placeholder="Max"
            value={priceRange.max}
            onChange={(e) =>
              setPriceRange({ ...priceRange, max: e.target.value })
            }
          />
        </div>
        <button className="apply-button">Apply</button>
      </div>

      {/* Discount Section */}
      <div className="filter-section">
        <h4 className="font-bold text-[14px]">Discount Percentage</h4>
        <ul>
          <li>
            <input type="checkbox" id="10off" />
            <label htmlFor="10off">10% or more</label>
          </li>
          <li>
            <input type="checkbox" id="20off" />
            <label htmlFor="20off">20% or more</label>
          </li>
          <li>
            <input type="checkbox" id="30off" />
            <label htmlFor="30off">30% or more</label>
          </li>
          <li>
            <input type="checkbox" id="40off" />
            <label htmlFor="40off">40% or more</label>
          </li>
          <li>
            <input type="checkbox" id="50off" />
            <label htmlFor="50off">50% or more</label>
          </li>
        </ul>
      </div>

      {/* Brand Section */}
      <div className="filter-section">
        <h4 className="font-bold text-[14px]">Brand</h4>
        <input
          type="text"
          placeholder="Search brand"
          className="brand-search"
        />
        <ul>
          {brands.map((brand) => (
            <li key={brand}>
              <input type="checkbox" id={brand} />
              <label htmlFor={brand}>{brand}</label>
            </li>
          ))}
        </ul>
      </div>

      {/* Size Section */}
      <div className="filter-section">
        <h4 className="font-bold text-[14px]">Size</h4>
        <ul>
          {sizes.map((size) => (
            <li key={size}>
              <input type="checkbox" id={size} />
              <label htmlFor={size}>{size}</label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FilterProduct;
