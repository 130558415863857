import React from "react";
import "./Storenavbar.css";
import Storenavbar from "./Storenavbar";
import Shopnavbar from "./Shopnavbar";

const Storetopbox = ({ storedata }) => {
  return (
    <div className="store-top-bar">
      <Storenavbar storedata={storedata} />
      {/* <Shopnavbar/> */}
    </div>
  );
};

export default Storetopbox;
