import React, { useState, useEffect } from "react";
import "./CollectionCard.css";
import NumberFormat from "react-number-format";
import Loadpending from "../Loadpending/Loadpending";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  AiFillHeart,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { addTocart } from "../../../redux/actions/cart";
import ProductDetailsCard from "../../Route/ProductDetailsCard/ProductDetailsCard";
import { Link } from "react-router-dom";

const Collectioncard = ({ data, isEvent, type }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  type = type === undefined ? true : type;

  const handleOverview = () => {};

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  const handleClick = () => {
    // //console.log(values)
  };

  return (
    <div
      className="shadow-sm collectioncard-container_ grow max-w-[250px] relative"
      onClick={handleClick}
    >
      <Link to={`/products/${data._id}`}>
        <img
          src={`${data.images && data.images[0]?.url}`}
          alt=""
          className="w-full h-full min-h-[230px] rounded-[5px] bg-center bg-no-repeat bg-contain relative flex justify-center items-center"
        />
      </Link>

      <div className="text-field w-full">
        <Link to={`/products/${data._id}`} className="w-full">
          <div className="w-full flex flex-col gap-x-1 text_field">
            <span>{data.name}</span>

            <div className="product_original_discount_price">
              <p>
                <NumberFormat
                  value={data.discountPrice}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"XOF "}
                />
              </p>
              <p>
                <NumberFormat
                  value={data.originalPrice}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"XOF "}
                />
              </p>
            </div>
          </div>
        </Link>

        <div className="shopping_icons_container">
          {click ? (
            <AiFillHeart
              size={22}
              className="shopping_icons"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="shopping_icons"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Add to wishlist"
            />
          )}
          {type && (
            <AiOutlineEye
              size={22}
              className="shopping_icons"
              onClick={() => setOpen(!open)}
              color="#333"
              title="Quick view"
            />
          )}
          <AiOutlineShoppingCart
            size={25}
            className="shopping_icons"
            onClick={() => addToCartHandler(data._id)}
            color="#444"
            title="Add to cart"
          />
          {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
        </div>
      </div>
    </div>
  );
};

export default Collectioncard;
