import React, { useState, useEffect } from "react";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";
import AllProducts from "../../components/Shop/AllProducts";
import { useSelector } from "react-redux";
import Loader from "../../components/Loading/Loading";
import CreateProduct from "../../components/Shop/CreateProduct";
import { IoCloseCircleOutline, IoCloseOutline } from "react-icons/io5";
import { EditBitton } from "../../redux/actions/buttonClick";
import Store from "../../redux/store";
import { productfieldparams } from "../../redux/actions/dataresponse";

const ShopAllProducts = () => {
  const { isLoading } = useSelector((state) => state.products);
  const { overlayButtonClick } = useSelector((state) => state.buttonclick);
  const [editbutton, setEditbutton] = useState(false);
  const [closeprop, setcloseprop] = useState(false);

  useEffect(() => {
    if (overlayButtonClick !== null) {
      Store.dispatch(productfieldparams(overlayButtonClick));
      setEditbutton(true);
      setcloseprop(true);
    }
  }, [overlayButtonClick]);

  const handleClose = () => {
    setEditbutton(false);
    setcloseprop(false);
    Store.dispatch(EditBitton(null));
  };

  return (
    <div className="w-full h-[100vh] flex flex-col relative">
      <DashboardHeader />
      {/* <div className="bg-black w-full h-full fixed top-0 z-10">c</div> */}

      {isLoading === true ? (
        <Loader
          type="bars"
          color="blue"
          text="Fetching Product"
          height="30%"
          width="80%"
        />
      ) : (
        editbutton === true && (
          <div className="bg-custom-gray w-full h-screen fixed top-0 z-40 flex justify-center items-center">
            <div className="w-[90%] h-full overflow-y-auto flex justify-center relative">
              <IoCloseCircleOutline
                size={35}
                className="absolute top-3 right-9 cursor-pointer"
                onClick={handleClose}
              />
              <CreateProduct overlay={closeprop} />
            </div>
          </div>
        )
      )}

      <div className="flex bg-[#002D62] justify-between relative w-full">
        <div className="w-[80px] sticky 800px:w-[330px]">
          <DashboardSideBar active={3} />
        </div>
        <div className="w-full justify-center bg-[#f9f9f9] m-1 overflow-y-auto flex">
          <AllProducts />
        </div>
      </div>
    </div>
  );
};

export default ShopAllProducts;
