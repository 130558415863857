import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header/Header";
import Lottie from "lottie-react";
import animationData from "../Assests/animations/107043-success.json";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const OrderSuccessPage = () => {
  return (
    <div>
      <Header />
      <Success />
      <Footer />
    </div>
  );
};

const Success = () => {

  const style = {
    height: 200,
  };

  return (
    <div className="w-full min-h-[400px] gap-3 flex flex-col justify-center items-center">
      <Lottie animationData={animationData} style={style} />
      
      <div className="w-full flex items-center justify-center gap-3">
        <BsFillCheckCircleFill color="green" size={30}/> 
        <h5 className="text-center text-[25px] font-[600] text-[green]">
          Order Successfully Placed! 😍
        </h5>
      </div>

      <Link to="/" className="p-2 rounded bg-[blue] text-white text-[14px]">Back to Store</Link>
    </div>
  );
};

export default OrderSuccessPage;
