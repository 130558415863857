import React from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  footercompanyLinks,
  footerProductLinks,
  footerSupportLinks,
} from "../../static/data";
import Logo from "../../Assests/shop_logo/kslogored.png";

const Footer = () => {
  return (
    <div className="bg-[#333] text-white">
      <div className="flex items-start justify-around gap-3 sm:px-8 px-5 py-10 sm:text-center flex-wrap">
        <ul className="p-2 text-center sm:text-start flex sm:block flex-col items-center min-w-[200px] grow">
          <img
            // src="https://res.cloudinary.com/dswna4lpk/image/upload/v1697226169/Bag_alt_pgykgr.png"
            src={Logo}
            alt=""
            // style={{ filter: "brightness(0) invert(1)" }}
            className="h-[60px] w-[80px] mb-3"
          />
          <p className="text-[12px]">
            The home and elements needed to <br /> create beautiful products.
          </p>
          <div className="flex items-center mt-[15px]">
            <AiFillFacebook size={25} className="cursor-pointer" />
            <AiOutlineTwitter
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
            <AiFillInstagram
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
            <AiFillYoutube
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
          </div>
        </ul>

        <ul className="p-2 text-center sm:text-start min-w-[200px] grow">
          <h1 className="mb-1 font-semibold">Company</h1>
          {footerProductLinks?.map((link, index) => (
            <li key={index}>
              <Link
                className="text-gray-400 hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={link?.link}
              >
                {link?.name}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="p-2 text-center sm:text-start  min-w-[200px] grow">
          <h1 className="mb-1 font-semibold">Shop</h1>
          {footercompanyLinks?.map((link, index) => (
            <li key={index}>
              <Link
                className="text-gray-400 hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={link?.link}
              >
                {link?.name}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="p-2 text-center sm:text-start min-w-[200px] grow">
          <h1 className="mb-1 font-semibold">Support</h1>
          {footerSupportLinks?.map((link, index) => (
            <li key={index}>
              <Link
                className="text-gray-400 hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                // to={link.link}
                to="/"
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex w-full items-center gap-2 text-center py-2 text-gray-400 text-sm px-8 flex-wrap md:justify-between justify-center">
        <p className="text-[12px] min-w-[200px]">
          <span className="text-[#56d879]">Subscribe</span> us to get our events
          and offers
        </p>

        <div className="flex gap-2 md:w-auto w-full justify-center">
          <input
            type="text"
            required
            placeholder="Enter your email..."
            className="text-gray-400 sm:w-72 w-full text-[12px] p-2 rounded focus:outline-none bg-[white]"
          />
          <button className="bg-[#56d879] hover:bg-teal-500 duration-300 px-5 py-2 text-[12px] rounded-md text-white w-auto">
            Submit
          </button>
        </div>
      </div>

      <div className="flex w-full items-center justify-center gap-3 text-center py-2 text-gray-400 text-sm mb-6 px-8 sm:justify-between">
        <span className="text-[12px]">
          Copyright &#169; <b>2023 Kira Stores</b>. All rights Reserved
        </span>
        {/* <span>Terms · Privacy Policy</span> */}
        <div className="sm:flex hidden items-center justify-center ">
          <img
            src="https://hamart-shop.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffooter-payment.a37c49ac.png&w=640&q=75"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
