import React, { useState } from "react";
import "./ProductPreview.css";
import { Carousel } from "react-carousel-minimal";
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";

const dataset = [
  {
    image:
      "https://cdn.britannica.com/s:800x450,c:crop/35/204435-138-2F2B745A/Time-lapse-hyper-lapse-Isle-Skye-Scotland.jpg",
  },
  {
    image:
      "https://static2.tripoto.com/media/filter/tst/img/735873/TripDocument/1537686560_1537686557954.jpg",
  },
  {
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Palace_of_Fine_Arts_%2816794p%29.jpg/1200px-Palace_of_Fine_Arts_%2816794p%29.jpg",
  },
  {
    image:
      "https://i.natgeofe.com/n/f7732389-a045-402c-bf39-cb4eda39e786/scotland_travel_4x3.jpg",
  },
];

const captionStyle = {
  fontSize: "2em",
  fontWeight: "bold",
};

const slideNumberStyle = {
  fontSize: "20px",
  fontWeight: "bold",
};

const ItemPreview = ({ dataImages }) => {
  const data = [];

  dataImages?.map((filteredItem) => {
    data.push({ image: filteredItem.url });
  });

  return (
    <div className="itempreview-container">
      <Carousel
        data={data}
        time={7000}
        width="250px"
        height="30vh"
        captionStyle={captionStyle}
        radius="15px"
        slideNumber={false}
        slideNumberStyle={slideNumberStyle}
        captionPosition="bottom"
        automatic={true}
        dots={false}
        pauseIconColor="white"
        pauseIconSize="40px"
        slideBackgroundColor="darkgrey"
        slideImageFit="cover"
        thumbnails={true}
        thumbnailWidth="52px"
        showNavBtn={false}
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          margin: "0px auto",
          justifyContent: "center",
        }}
      />

      <div className="flex flex-col w-full gap-2 mt-2 pt-3 share_product">
        <span className="text-[14px] font-[500] text-[#333]">
          SHARE THIS PRODUCT
        </span>
        <div className="flex items-center gap-2">
          <FaFacebook size={27} color="blue" className="cursor-pointer" />
          <AiFillTwitterCircle
            size={29}
            color="#333"
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default ItemPreview;
