import React from "react";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";
import DashboardHero from "../../components/Shop/DashboardHero";
import Store from "../../redux/store";

const ShopDashboardPage = () => {
  Store.dispatch({
    type: "editRefresh",
  });

  return (
    <div className="w-full min-w-[360px] h-[100vh] flex flex-col">
      <DashboardHeader />
      <div className="flex bg-[#FFBA00] justify-between relative w-full">
        <div className="w-[80px] sticky 800px:w-[330px]">
          <DashboardSideBar active={1} />
        </div>
        <div className="w-full justify-center bg-[#f9f9f9] m-1 overflow-y-auto flex">
          <DashboardHero />
        </div>
      </div>
    </div>
  );
};

export default ShopDashboardPage;
