import React from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";
import AdminDashboardMain from "../components/Admin/AdminDashboardMain";
// import Header from "../components/Layout/Header/header/Header";

const AdminDashboardPage = () => {
  return (
    <div className="w-full overflow-x-auto h-[100vh] relative">
      <AdminHeader />
      <div className="flex items-start justify-between w-full h-full overflow-y-auto relative">
        <div className="w-[50px] 800px:w-[300px] h-full sticky top-0 left-0">
          <AdminSideBar active={1} />
          {/* <Header /> */}
        </div>
        <AdminDashboardMain />
      </div>
    </div>
  );
};

export default AdminDashboardPage;
