import React from 'react'
import './Progressbar.css'

const Progressbar = ({stock}) => {
  const cap_stock = 100
  return (
    <div className='main-progressbar-container bg-[aqua] h-[30px]'>
        <div className='subprogressbar' style={{width: `${(stock/cap_stock)*100}%`, backgroundColor: stock <= 10 ? "red" : "#FF9900"}}/>
    </div>
  )
}

export default Progressbar