import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const { isLoading, isAuthenticated, user } = useSelector(
    (state) => state.user
  );

  if (isLoading === false) {
    if (isAuthenticated === false && user?.role === "user") {
      return <Navigate to="/login" replace />;
    } else if (isAuthenticated === true && user?.role === "user") {
      return children;
    }
    return children;
  }
};

export default ProtectedRoute;
