import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isSeller: false,
  isLoading: true,
  isloading: 0,
  seller: null,
  sellers: [],
};

export const sellerReducer = createReducer(initialState, {
  LoadSellerRequest: (state) => {
    state.isLoading = true;
  },

  LoadSellerSuccess: (state, action) => {
    state.isSeller = true;
    state.isLoading = false;
    state.seller = action.payload;
  },

  LoadSellerFail: (state, action) => {
    state.isSeller = false;
    state.isLoading = false;
    state.error = action.payload;
  },

  // remove sellers ---admin
  removeSellerRequest: (state) => {
    state.isloading = 1;
  },
  removeSellerSuccess: (state, action) => {
    state.isloading = 0;
    state.sellers = action.payload;
  },
  removeSellerFailed: (state, action) => {
    state.isloading = 0;
    state.error = action.payload;
  },

  // get all sellers ---admin
  getAllSellersRequest: (state) => {
    state.isloading = 2;
  },
  getAllSellersSuccess: (state, action) => {
    state.isloading = 0;
    state.sellers = action.payload;
  },
  getAllSellerFailed: (state, action) => {
    state.isloading = 0;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
