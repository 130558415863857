import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: 0,
  categorylist: [],
};

export const categoryReducer = createReducer(initialState, {
  // fetch all available categories
  getAllCategories: (state) => {
    state.isLoading = 3;
  },

  getAllCategoriesSuccess: (state, action) => {
    state.isLoading = 0;
    state.categorylist = action.payload;
  },
  getAllCategoriesFailed: (state, action) => {
    state.isLoading = 0;
    state.error = action.payload;
  },

  // careate all categories
  createCategories: (state) => {
    state.isLoading = 1;
  },
  createCategoriesSuccess: (state, action) => {
    state.isLoading = 0;
    state.categorylist = action.payload;
  },
  createCategoriesFailed: (state, action) => {
    state.isLoading = 0;
    state.error = action.payload;
  },

  // remove/delete category
  deleteCategory: (state) => {
    state.isLoading = 2;
  },

  deleteCategoriesSuccess: (state, action) => {
    state.isLoading = 0;
    state.categorylist = action.payload;
  },

  deleteCategoriesFailed: (state, action) => {
    state.isLoading = 0;
    state.error = action.payload;
  },
});
